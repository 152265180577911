.contactBnr {
    margin-top: 35px;
    &-list {
        width: 592px;
        margin: 0 auto;
        @include clearfix;
    }
    &-item {
        float: left;
        width: 278px;
    }
    &-btn {
        width: 250px;
        margin-right: 64px;
    }
}