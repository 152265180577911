#category_list .banner-top {
    @include clearfix;
    .description {
        padding: 25px 15px 40px;
    }
    .title {
        font-size: 24px;
        font-size: 2.4rem;
        line-height: 1;
        background: $themeColor;
        padding: 12px 15px;
    }
}
#category_list .typeface-list {
    margin-bottom: 50px;
    @include clearfix;
    .lists {
        border-collapse: collapse;
        width: 100%;
        margin-top: 20px;
        thead tr th {
            border: 1px solid $borderColor;
            background: $themeColor;
            padding: 1em 0;
            text-align: center;
            width: 20%;
            font-size: 15px;
            font-size: 1.5rem;
            span {
                font-size: 12px;
                font-size: 1.2rem;
            }
        }
        tbody tr td {
            border: 1px solid $borderColor;
            padding: 0.3em 0;
            text-align: center;
        }
    }
}
#category_list .size-guide {
    margin-bottom: 50px;
    @include clearfix;
    .title {
        display: table;
        line-height: 1;
        height: 30px;
        border-left: 4px solid $shopColor;
        .tb-1 {
            font-size: 21px;
            font-size: 2.1rem;
            letter-spacing: 3px;
            padding-left: 15px;
            display: table-cell;
            vertical-align: middle;
        }
    }
    .guide-table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 20px;
        tr {
            &:first-child td {
                background: $themeColor;
            }
            td {
                width: 14.1666667%;
                border: 1px solid $borderColor;
                padding: 2em 0;
                text-align: center;
                &:first-child {
                    background: $themeColor;
                    width: 15%;
                }
                span {
                    font-size: 12px;
                    font-size: 1.2rem;
                }
            }
        }
    }
}
#category_list .product-list {
    .title {
        display: table;
        padding-left: 15px;
        line-height: 1.2;
        height: 30px;
        border-left: 4px solid $shopColor;
        .tb-1 {
            font-size: 21px;
            font-size: 2.1rem;
            letter-spacing: 3px;
            padding-left: 15px;
            display: table-cell;
            vertical-align: middle;
        }
    }
    .break-list {
        @include clearfix;
        .lists {
            float: left;
            margin: 0 20px 40px 0;
            width: 240px;
            &:nth-of-type(3n) {
                margin-right: 0;
            }
            &:nth-of-type(3n+1) {
                clear: both;
            }
        }
    }
}
#category_list .product-list .break-list .lists {
    .thumbnail {
        .product-pic {
            width: 100%;
            height: 240px;
            border: 1px solid $borderColor;
            text-align: center;
            position: relative;
            margin-bottom: 10px;
            box-sizing: border-box;
            img {
                margin: auto;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        .text {
            font-size: 16px;
            font-size: 1.6rem;
            .price {
                color: #ff0000;
                margin-top: 30px;
            }
        }
        a {
            display: block;
            text-decoration: none;
        }
    }
    .shipping {
        display: block;
        padding: 10px 0;
        line-height: 1;
        li {
            display: inline-block;
            margin-right: 4px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .box-basket {
        width: 100%;
        display: table;
        background: $themeColor;
        padding: 10px 14px;
        margin-top: 15px;
        text-align: right;
        box-sizing: border-box;
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;
        .side-input {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            input {
                height: 24px;
                line-height: 24px;
                box-sizing: border-box;
                padding: 4px 10px;
                margin: 0 10px 0 5px;
                width: 40px;
                background: #fff;
                border: 1px solid $borderColor;
                border-radius: 0;
            }
        }
        .side-basket {
            display: table-cell;
            vertical-align: middle;
        }
    }
}
#category_list .banner-bottom {
    text-align: center;
    margin-top: 100px;
    a {
        display: block;
    }
}