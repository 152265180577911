.payment {
    &-list {
        margin-bottom: 15px;
        @include clearfix;
    }
    &-item {
        float: left;
        &-card {
            width: 65px;
            margin-left: 5px;
            &:first-child {
                margin-left: 0;
            }
        }
        &-time {
            width: 60px;
            margin-left: 10px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    &-txt {
        margin-bottom: 10px;
    }
}