@charset "UTF-8";

@mixin shopBody {
    body {
        font: #{$fs}/#{$lh} $sans_serif;
        color: $shopColor;
        text-align: left;
        background: #fff;
    }
}
@mixin cartBody {
    body {
        font: #{$fs}/#{$lh} $cart_sans_serif;
        color: $cartColor;
        text-align: left;
        background: #fff;
    }
}

@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin rgba-background($rgba_color){
    @include filter-gradient($rgba_color, $rgba_color);
    @if $legacy-support-for-ie6 or $legacy-support-for-ie7 or $legacy-support-for-ie8 {
        background: transparent;
        // set filter as none for IE9+, because IE9+ supprot RGBa
        :root & {
        filter: none\0/IE9;}
    }
    background: $rgba_color;
}

@mixin gradient($start-color, $end-color, $orientation) {
    @if $orientation == vertical {
        background: $end-color;
        // vertical
        background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
        background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
        background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
        background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
        background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=0 );
    }
    @else if $orientation == vertical3step {
        // horizontal
        background: $start-color;
        background: -moz-linear-gradient(top, $start-color 0%, $end-color 48%, $end-color 52%, $start-color 100%);
        background: -webkit-linear-gradient(top, $start-color 0%,$end-color 48%,$end-color 52%,$start-color 100%);
        background: linear-gradient(to bottom, $start-color 0%,$end-color 48%,$end-color 52%,$start-color 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=1 );
    }
    @else if $orientation == horizontal {
        // horizontal
        background: $start-color;
        background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
        background: -webkit-linear-gradient(left,  $start-color 0%, $end-color 100%);
        background: -o-linear-gradient(left,  $start-color 0%, $end-color 100%);
        background: -ms-linear-gradient(left,  $start-color 0%, $end-color 100%);
        background: linear-gradient(to right,  $start-color 0%, $end-color 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=1 );
    }
    @else if $orientation == diagonal {
        // diagonal
        background: $start-color;
        background: -moz-linear-gradient(-45deg,  $start-color 17%, $end-color 50%, $start-color 87%);
        background: -webkit-linear-gradient(-45deg,  $start-color 17%, $end-color 50%, $start-color 87%);
        background: linear-gradient(135deg, $start-color 17%, $end-color 50%, $start-color 87%);
    }
    @else {
        // radial
        background: -moz-radial-gradient(center, ellipse cover,  $start-color 0%, $end-color 100%);
        background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
        background: -webkit-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
        background: -o-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
        background: -ms-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
        background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$start-color}', endColorstr='#{$end-color}',GradientType=1 );
    }
}

@mixin placeholderStyle($placeholderColor) {
    &:placeholder-shown {
        color: $placeholderColor;
        line-height: 1.3;
    }
    &::-webkit-input-placeholder {
        color: $placeholderColor;
        line-height: 1.3;
    }
    &:-moz-placeholder {
        color: $placeholderColor;
        line-height: 1.3;
        opacity: 1;
    }
    &::-moz-placeholder {
        color: $placeholderColor;
        line-height: 1.3;
        opacity: 1;
    }
    &:-ms-input-placeholder {
        color: $placeholderColor;
        line-height: 1.3;
    }
}
