.errMsg {
    margin-left: 1em;
    padding-bottom: 1px;
    display: block;
    line-height: 1.4;
    text-indent: -.9em;
}
.formError {
    padding-bottom: 13px;
    display: block;
}
.fixed {
    padding-bottom: 0;
}
.formError .formErrorClose {
    border: solid #252525 2px;
    border-radius: 9px 9px 9px 9px;
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: -4px;
    right: -4px;
    color: #efefef;
    background: #333;
    font-weight: bold;
    text-align: center;
    line-height: middle;
    cursor: pointer;
    box-shadow: 1px -1px 3px #888;
    _z-index: 2;
    &:hover {
        background: #666;
    }
}
.fixed .formErrorClose {
    display: none;
}
.formError .formErrorContent {
    border-radius: 3px;
    padding: 5px;
    position: relative;
    color: #fff;
    background: #252525;
    font-size: 11px;
    box-shadow: 0px 0px 6px #888;
    _z-index: 1;
}
.fixed .formErrorContent {
    border-radius: 0;
    color: #eb5c01;
    font-size: 12px;
    font-size: 1.2rem;
    background: #fff;
    box-shadow: none;
}
.fadeOut {
    opacity: .2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
    filter: alpha(opacity=20);
}
.formError .formErrorArrow {
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 0;
    left: 20px;
    _z-index: 0;
}
.fixed .formErrorArrow {
    display: none;
}
.formError .formErrorArrowBottom {
    margin: -6px;
    top: 0;
}
.fixed .formErrorArrowBottom {
    display: none;
}
.formError {
    .formErrorArrow div {
        margin: 0 auto;
        display: block;
        height: 1px;
        background: #252525;
        line-height: 0px;
        font-size: 0px;
        box-shadow: 0px 2px 3px #888;
    }
    .formErrorArrowBottom div {
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }
    .formErrorArrow {
        .line10 {
            width: 19px;
        }
        .line9 {
            width: 17px;
        }
        .line8 {
            width: 15px;
        }
        .line7 {
            width: 13px;
        }
        .line6 {
            width: 11px;
        }
        .line5 {
            width: 9px;
        }
        .line4 {
            width: 7px;
        }
        .line3 {
            width: 5px;
        }
        .line2 {
            width: 3px;
        }
        .line1 {
            width: 1px;
        }
    }
}