.page {
    &-intro {
        margin-bottom: 35px;
        padding-left: 21px;
        &02 {
            padding: 5px 0;
            margin-bottom: 45px;
            .word {
                margin-top: 3px;
                vertical-align: top;
            }
        }
    }
    &-box {
        margin-bottom: 35px;
        @include clearfix;
    }
    &-txtArea {
        width: 395px;
        float: left;
    }
    &-txt {
        line-height: 1.7;
        margin-bottom: 25px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &-img {
        width: 300px;
        float: right;
        img {
            max-width: 100%;
        }
    }
    &-bnr {
        width: 430px;
        margin: 0 auto;
    }
}