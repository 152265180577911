.sample {
    &-list {
        width: 734px;
        margin: 0 auto 50px;
        @include clearfix;
    }
    &-item {
        width: 164px;
        float: left;
        margin: 0 0 9px 25px;
        &:nth-child(4n+1) {
            margin-left: 0;
        }
    }
    &-img {
        box-sizing: border-box;
        border: 1px solid $borderColor;
        text-align: center;
        padding: 40px 0;
        margin-bottom: 9px;
    }
    &-name {
        min-height: 42px;
    }
}