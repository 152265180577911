@charset "UTF-8";
@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 100;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 300;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 900;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 100;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 300;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 400;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format("opentype");
}

@font-face {
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 900;
  src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format("opentype");
}

.errMsg {
  margin-left: 1em;
  padding-bottom: 1px;
  display: block;
  line-height: 1.4;
  text-indent: -.9em;
}

.formError {
  padding-bottom: 13px;
  display: block;
}

.fixed {
  padding-bottom: 0;
}

.formError .formErrorClose {
  border: solid #252525 2px;
  border-radius: 9px 9px 9px 9px;
  display: block;
  width: 16px;
  height: 16px;
  position: absolute;
  top: -4px;
  right: -4px;
  color: #efefef;
  background: #333;
  font-weight: bold;
  text-align: center;
  line-height: middle;
  cursor: pointer;
  box-shadow: 1px -1px 3px #888;
  _z-index: 2;
}

.formError .formErrorClose:hover {
  background: #666;
}

.fixed .formErrorClose {
  display: none;
}

.formError .formErrorContent {
  border-radius: 3px;
  padding: 5px;
  position: relative;
  color: #fff;
  background: #252525;
  font-size: 11px;
  box-shadow: 0px 0px 6px #888;
  _z-index: 1;
}

.fixed .formErrorContent {
  border-radius: 0;
  color: #eb5c01;
  font-size: 12px;
  font-size: 1.2rem;
  background: #fff;
  box-shadow: none;
}

.fadeOut {
  opacity: .2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.formError .formErrorArrow {
  width: 15px;
  height: 15px;
  position: absolute;
  bottom: 0;
  left: 20px;
  _z-index: 0;
}

.fixed .formErrorArrow {
  display: none;
}

.formError .formErrorArrowBottom {
  margin: -6px;
  top: 0;
}

.fixed .formErrorArrowBottom {
  display: none;
}

.formError .formErrorArrow div {
  margin: 0 auto;
  display: block;
  height: 1px;
  background: #252525;
  line-height: 0px;
  font-size: 0px;
  box-shadow: 0px 2px 3px #888;
}

.formError .formErrorArrowBottom div {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.formError .formErrorArrow .line10 {
  width: 19px;
}

.formError .formErrorArrow .line9 {
  width: 17px;
}

.formError .formErrorArrow .line8 {
  width: 15px;
}

.formError .formErrorArrow .line7 {
  width: 13px;
}

.formError .formErrorArrow .line6 {
  width: 11px;
}

.formError .formErrorArrow .line5 {
  width: 9px;
}

.formError .formErrorArrow .line4 {
  width: 7px;
}

.formError .formErrorArrow .line3 {
  width: 5px;
}

.formError .formErrorArrow .line2 {
  width: 3px;
}

.formError .formErrorArrow .line1 {
  width: 1px;
}

/* 基本定義 */
body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
}

html {
  margin: 0;
  padding: 0;
  border: 0;
  line-height: 1;
  font-size: 62.5%;
  vertical-align: baseline;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle;
}

q, blockquote {
  quotes: none;
}

q::before, q::after, blockquote::before, blockquote::after {
  content: "";
  content: none;
}

a img {
  border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

a {
  color: #444;
  text-decoration: none;
  transition: .3s;
}

@media only screen and (min-width: 1025px) {
  a:hover {
    opacity: .8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    text-decoration: underline;
  }
}

img {
  vertical-align: bottom;
}

body {
  font: 14px/1.5 "Noto Sans JP", "游ゴシック", YuGothic, "Yu Gothic", "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", Osaka, Helvetica, Arial, sans-serif;
  color: #444;
  text-align: left;
  background: #fff;
}

.theme-heading01 {
  font-size: 2.1rem;
  color: #444;
  border-left: 4px solid #2b273a;
  padding-left: 17px;
  line-height: 1.3;
  margin-bottom: 15px;
}

.theme-eng {
  font-size: 1.3rem;
  line-height: 28px;
  color: #94b9b6;
  padding-left: 1em;
}

.theme-pagetit {
  margin-bottom: 25px;
}

.theme-tit {
  padding-left: 40px;
  position: relative;
  margin-bottom: 15px;
}

.theme-tit::after {
  content: '';
  display: block;
  width: 654px;
  height: 1px;
  background: #fff;
  position: absolute;
  top: 50%;
  right: 0;
}

.theme-tit-square {
  background: url(icn_square.png) 15px center no-repeat;
}

.theme-tit-rectangle {
  background: url(icn_rectangle.png) 15px center no-repeat;
}

.theme-tit-other {
  background: url(icn_other.png) 15px center no-repeat;
}

.theme-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.theme-table thead th {
  padding: 18px 0;
}

.theme-table .bd-none td {
  border-bottom: none;
}

.theme-table .bd-none + tr td {
  border-top: none;
}

.theme-table th, .theme-table td {
  border: 1px solid #ccc;
  color: #444;
}

.theme-table th {
  background: #f6f6f6;
  text-align: center;
  font-size: 15px;
  font-size: 1.5rem;
}

.theme-table td {
  text-align: center;
  background: #fff;
}

.theme-table-note {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: normal;
}

.theme-table.is-short {
  width: 542px;
}

.theme-table-size td {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  padding: 19px 0 18px;
}

.theme-txtTable {
  margin: 0 0 35px 0;
}

.theme-txtTable th, .theme-txtTable td {
  text-align: left;
  box-sizing: border-box;
  padding: 23px 20px;
}

.theme-txtTable th {
  vertical-align: top;
  width: 190px;
}

.theme-txtTable td {
  line-height: 1.6;
}

.theme-txtTable-emph {
  font-weight: bold;
}

.theme-txtTable-attention {
  color: #f00;
}

.theme-txtTable-link {
  text-decoration: underline;
}

.theme-txtTable-link:hover {
  text-decoration: none;
}

.theme-txtTable-list {
  margin-bottom: 1.6em;
}

.theme-txtTable-list:last-child {
  margin-bottom: 0;
}

.theme-txtTable-list dt {
  font-weight: bold;
}

.theme-txtTable th.table-title {
  text-align: center;
  font-weight: bold;
}

input[type=text], input[type=password], input[type=email], input[type=tel], input[type=search], input[type=zip] {
  appearance: none;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #b9c0bc;
  padding: 15px 10px;
  max-width: 100%;
  box-sizing: border-box;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "Yu Gothic", "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", Osaka, Helvetica, Arial, sans-serif;
}

input[type=checkbox] {
  appearance: none;
  border: none;
  vertical-align: middle;
  margin: 0;
}

input[type=checkbox] + label {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

input[type=checkbox] + label::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border: 1px solid #b9c0bc;
  background: #fff;
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}

input[type=checkbox] + label::after {
  content: "";
  display: block;
  width: 6px;
  height: 12px;
  position: absolute;
  top: 2px;
  left: -16px;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  transition: .2s;
  transform: rotate(45deg);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

input[type=checkbox]:checked + label:after {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input[type=radio] {
  appearance: none;
  display: none;
  border: none;
  vertical-align: middle;
  margin: 0;
}

input[type=radio] + label {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

input[type=radio] + label::before {
  content: "";
  display: block;
  width: 12px;
  height: 12px;
  border: 1px solid #b9c0bc;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -20px;
  transform: translateY(-50%);
}

input[type=radio] + label::after {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -16px;
  transition: .2s;
  transform: translateY(-50%);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

input[type=radio]:checked + label:after {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

textarea {
  padding: 15px 10px;
  border: 1px solid #b9c0bc;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "Yu Gothic", "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", Osaka, Helvetica, Arial, sans-serif;
}

.sp {
  display: none !important;
}

.image-title {
  margin-bottom: 25px;
}

.item-title {
  background-color: #f5f4ef;
  border: 1px solid #e9e9e9;
  margin-bottom: 25px;
  padding: 9px 15px 11px;
  font-size: 2.4rem;
  line-height: 1.2;
  letter-spacing: .1em;
}

.sub-title {
  margin-bottom: 25px;
  padding: 2px 0 3px 15px;
  border-left: 4px solid #2b273a;
  font-size: 21px;
  font-size: 2.1rem;
  line-height: 1.2;
  letter-spacing: .1em;
}

.sub-title span, .sub-title strong {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2;
}

.sub-title strong {
  margin-left: .4em;
  font-size: 30px;
  font-size: 3rem;
}

/* basic setting
======================================================*/
/* clearfix */
.clearfix {
  clear: both;
}

.clearfix:before, .clearfix:after {
  content: ' ';
  display: table;
}

.clearfix:after {
  clear: both;
}

/* Layout
======================================================*/
.l-inner {
  width: 1000px;
  margin: 0 auto;
}

.l-header-left {
  width: auto;
  float: left;
}

.l-header-right {
  width: 503px;
  float: right;
}

.l-header-right::after {
  content: "";
  display: table;
  clear: both;
}

.l-contents {
  width: 1000px;
  margin: 0 auto 75px;
}

.l-contents::after {
  content: "";
  display: table;
  clear: both;
}

#main {
  float: right;
  width: 760px;
}

#navi {
  float: left;
  width: 210px;
}

/* Header
======================================================*/
#head {
  padding: 30px 0 0;
  margin-bottom: 13px;
}

#head-in {
  margin-bottom: 23px;
}

#head-tbl::after {
  content: "";
  display: table;
  clear: both;
}

.header-txt {
  font-size: 11px;
  font-size: 1.1rem;
  line-height: 27px;
}

.header-logo {
  padding: 11px 0 7.5px 0;
}

.header-list {
  width: 215px;
  float: left;
  padding-top: 4px;
}

.header-list::after {
  content: "";
  display: table;
  clear: both;
}

.header-item {
  width: 100%;
}

.header-btn {
  width: 265px;
  float: right;
}

.header-btn::after {
  content: "";
  display: table;
  clear: both;
}

.header-btn-item {
  width: 130px;
  float: left;
  margin-left: 5px;
}

.header-btn-item:nth-child(odd) {
  margin-left: 0;
}

.header-btn-item:nth-child(-n+2) {
  margin-bottom: 5px;
}

/* Gnavi
======================================================*/
.gnavi {
  background: #94b9b6;
  border-top: 1px solid #fff;
}

.gnavi-list {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  height: 51px;
}

.gnavi-list::after {
  content: "";
  display: table;
  clear: both;
}

.gnavi-list .shachihata_text {
  letter-spacing: 0.45em;
}

.gnavi-item {
  height: 100%;
  position: relative;
}

.gnavi-item::before {
  content: "";
  display: block;
  width: 1px;
  height: 24px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.gnavi-item:first-child::before {
  display: none;
}

.gnavi-item a {
  height: 100%;
  display: block;
  padding: 15px 28px;
  color: #fff;
  box-sizing: border-box;
}

.gnavi-item a:hover {
  text-decoration: none;
}

/* Footer
======================================================*/
.footer {
  background: #999;
  padding: 40px 0 25px;
  position: relative;
}

.footer-copy {
  color: #fff;
  text-align: center;
  font-size: 1.2rem;
}

.footer-copy .big {
  font-size: 1.6rem;
  padding: 0 10px;
}

.fnavi {
  margin: 0 auto 40px;
  width: 910px;
}

.fnavi::after {
  content: "";
  display: table;
  clear: both;
}

.fnavi-left, .fnavi-right {
  width: 430px;
}

.fnavi-left::after, .fnavi-right::after {
  content: "";
  display: table;
  clear: both;
}

.fnavi-left {
  float: left;
}

.fnavi-right {
  float: right;
}

.fnavi-tit {
  color: #fff;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  padding: 7px 0;
  margin-bottom: 15px;
  text-align: center;
}

.fnavi-Bottom {
  margin-bottom: 30px;
}

.fnavi-box {
  width: 190px;
  float: left;
  margin: 0 50px 30px 0;
}

.fnavi-box:nth-of-type(-n+2) {
  margin-top: 0;
}

.fnavi-box:nth-of-type(2n) {
  margin-right: 0;
  margin-bottom: 0;
}

.fnavi-box:nth-of-type(2n+1) {
  clear: both;
}

.fnavi-box.is-right {
  float: right;
  margin: 0;
}

.fnavi-item {
  font-size: 1.3rem;
  line-height: 2;
  padding-left: 15px;
  width: 100%;
}

.fnavi-item a {
  position: relative;
  display: block;
  color: #fff;
  padding-left: 1em;
  font-weight: normal;
}

.fnavi-item a::before {
  content: '';
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(45deg);
}

#pagetop {
  width: 48px;
  height: 48px;
  z-index: 5;
  right: 20px;
}

#pagetop a {
  width: 48px;
  height: 48px;
  background: #94b9b6;
  display: block;
  text-align: center;
  position: relative;
  border-radius: 50%;
}

#pagetop a::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(-45deg);
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
}

#pagetop a:hover::before {
  animation: pagetop 0.8s linear;
}

#pagetop.is-static {
  position: absolute;
  top: -68px;
}

#pagetop.is-fixed {
  position: fixed;
  bottom: 20px;
}

@keyframes pagetop {
  0% {
    transform: translateY(0) rotate(-45deg);
  }
  25% {
    transform: translateY(-2px) rotate(-45deg);
  }
  50% {
    transform: translateY(0) rotate(-45deg);
  }
  75% {
    transform: translateY(-2px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

a:hover#mttl {
  text-decoration: none;
}

/* TOPボックス */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track::after {
  content: "";
  display: table;
  clear: both;
}

.slick-track::before {
  content: "";
  display: table;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

.slick-slide a {
  display: block;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide img.slick-loading img {
  display: none;
}

.slick-slide img.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("ajax-loader.gif") center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 25px;
  width: 25px;
  background: transparent;
  color: transparent;
  top: 50%;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:focus,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:focus::before,
.slick-next:focus::before {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.slick-prev {
  left: -13%;
  background: url(../img/button/btn_prev.png) 0 0/25px no-repeat;
}

.slick-next {
  right: -13%;
  background: url(../img/button/btn_next.png) 0 0/25px no-repeat;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -20px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 10px;
  width: 10px;
  padding: 0;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 50%;
  background: #ddd;
}

.slick-dots li button {
  font-size: 0;
  appearance: none;
  border: none;
  background: transparent;
}

.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:focus:before {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.slick-dots li.slick-active {
  background: #999;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.visual {
  padding-top: 17px;
  width: 1000px;
  margin: 0 auto;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  transition: opacity .3s;
  height: 347px;
}

.visual::after {
  content: "";
  display: table;
  clear: both;
}

.visual.slick-slider {
  margin-bottom: 58px;
}

.visual.is-loading {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}

.visual-item {
  width: 1000px;
  float: left;
}

.top-box {
  margin-bottom: 35px;
}

.top-scene-list {
  margin-left: -26px;
}

.top-scene-list::after {
  content: "";
  display: table;
  clear: both;
}

.top-scene-item {
  float: left;
  width: 365px;
  margin: 0 0 22px 26px;
  border: 1px solid #fff;
}

.top-scene-item.is-small {
  width: 234px;
}

.top-category-tit::after {
  display: none;
}

.top-category-box {
  background-color: #f5f4ef;
  padding: 32px 32px 12px;
  margin: -25px 0 20px;
}

.top-category-list::after {
  content: "";
  display: table;
  clear: both;
}

.top-category-item {
  width: 214px;
  float: left;
  margin: 0 0 20px 26px;
}

.top-category-item:nth-child(3n+1) {
  margin-left: 0;
}

.top-category-other {
  width: 334px;
  float: left;
  margin-bottom: 20px;
}

.top-category-other:nth-child(odd) {
  margin-right: 26px;
}

.top-shopping-list {
  background-color: #f5f4ef;
  padding: 32px 32px 12px;
}

.top-shopping-list::after {
  content: "";
  display: table;
  clear: both;
}

.top-shopping-item {
  width: 334px;
  float: left;
  margin-bottom: 20px;
}

.top-shopping-item:nth-child(odd) {
  margin-right: 26px;
}

.top-aboutUs {
  margin-bottom: 45px;
}

.top-aboutUs-wrap {
  display: flex;
  justify-content: space-between;
}

.top-aboutUs-img {
  display: block;
  width: 380px;
  order: 1;
  margin-right: 14px;
}

.top-aboutUs-img img {
  width: 100%;
  height: auto;
}

.top-aboutUs-text {
  width: 366px;
  order: 2;
}

.top-aboutUs-text-item {
  font-size: 1.4rem;
  margin-bottom: 18px;
}

.top-aboutUs-text-item:last-child {
  margin-bottom: 0;
}

.contactBnr {
  margin-top: 35px;
}

.contactBnr-list {
  width: 592px;
  margin: 0 auto;
}

.contactBnr-list::after {
  content: "";
  display: table;
  clear: both;
}

.contactBnr-item {
  float: left;
  width: 278px;
}

.contactBnr-btn {
  width: 250px;
  margin-right: 64px;
}

/* Components
======================================================*/
.page-intro {
  margin-bottom: 35px;
  padding-left: 21px;
}

.page-intro02 {
  padding: 5px 0;
  margin-bottom: 45px;
}

.page-intro02 .word {
  margin-top: 3px;
  vertical-align: top;
}

.page-box {
  margin-bottom: 35px;
}

.page-box::after {
  content: "";
  display: table;
  clear: both;
}

.page-txtArea {
  width: 395px;
  float: left;
}

.page-txt {
  line-height: 1.7;
  margin-bottom: 25px;
}

.page-txt:last-child {
  margin-bottom: 0;
}

.page-img {
  width: 300px;
  float: right;
}

.page-img img {
  max-width: 100%;
}

.page-bnr {
  width: 430px;
  margin: 0 auto;
}

/* TOPナビ */
.top-nav {
  font-size: 1.2rem;
  margin: 0 0 13px 24px;
  color: #444;
}

/* List */
.list-col1 {
  margin-top: 15px;
}

.list-col1 li a {
  display: block;
}

.list-col1-center {
  margin-bottom: 20px;
}

.list-col1-center li a {
  display: block;
  width: 60%;
  margin: 0 auto;
}

.list-col3 {
  margin-bottom: 20px;
}

.list-col3::after {
  content: "";
  display: table;
  clear: both;
}

.list-col3-item {
  width: 236px;
  float: left;
  margin-left: 26px;
}

.list-col3-item:first-child {
  margin-left: 0;
}

.list-col2 {
  margin-bottom: 40px;
}

.list-col2::after {
  content: "";
  display: table;
  clear: both;
}

.list-col2-item {
  width: 367px;
  float: left;
}

.list-col2-item:first-child {
  margin-right: 26px;
}

.list-col2 + .list-col2 {
  margin-top: -20px;
}

.list-zaishitsu {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.list-zaishitsu-item {
  opacity: 0;
  transition: opacity .3s;
  width: 230px;
  float: left;
  color: #444;
  margin: 0 0 30px 22px;
}

.list-zaishitsu-item:nth-child(4n+1) {
  margin-left: 0;
}

.list-zaishitsu-item.is-loaded {
  opacity: 1;
}

.list-zaishitsu-item img {
  margin-bottom: 13px;
}

.list-zaishitsu-title {
  width: 103px;
  height: 103px;
  border: 1px solid #fff;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
}

.list-zaishitsu-num {
  display: block;
  font-size: 31px;
  line-height: 31px;
  padding: 22px 0 16px 0;
}

.list-zaishitsu-wrap {
  display: flex;
  flex-basis: 630px;
  margin-bottom: 33px;
}

.list-zaishitsu-txt {
  padding-right: 27px;
  line-height: 1.6;
}

.list-zaishitsu-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.list-zaishitsu-note {
  font-size: 1.2rem;
  color: #999;
  display: block;
  padding-top: 1.5em;
}

/* Product List */
/* Product Detail */
#product_detail .item_detail::after {
  content: "";
  display: table;
  clear: both;
}

#product_detail .item_detail .thumnail {
  width: 300px;
  float: left;
  text-align: center;
  border: 1px solid #fff;
}

#product_detail .item_detail .description {
  width: 433px;
  float: right;
}

#product_detail .item_detail .description .title {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: normal;
}

#product_detail .item_detail .description .price {
  font-size: 22px;
  font-size: 2.2rem;
  color: #ff0000;
  font-weight: normal;
}

#product_detail .item_detail .description .shipping {
  display: block;
  padding: 10px 0 20px;
  line-height: 1;
}

#product_detail .item_detail .description .shipping li {
  display: inline-block;
  margin-right: 8px;
}

#product_detail .item_detail .description .detail-list {
  display: block;
  border-top: 1px dotted #999;
}

#product_detail .item_detail .description .detail-list li {
  margin: 20px 3px 0;
  line-height: 1;
}

#product_detail .item_detail .description .box-basket {
  width: 100%;
  display: table;
  background: #f6f6f6;
  padding: 10px 14px;
  margin-top: 35px;
  text-align: right;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

#product_detail .item_detail .description .box-basket .side-input {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

#product_detail .item_detail .description .box-basket .side-input input {
  height: 24px;
  line-height: 24px;
  box-sizing: border-box;
  padding: 4px 10px;
  margin: 0 15px 0 5px;
  width: 90px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 0;
}

#product_detail .item_detail .description .box-basket .side-basket {
  display: table-cell;
  vertical-align: middle;
}

#product_detail .syousai-list {
  padding: 40px 15px;
}

#product_detail .syousai-list::after {
  content: "";
  display: table;
  clear: both;
}

#product_detail .syousai-list .list-break {
  text-align: center;
  display: block;
  overflow: hidden;
  margin-top: 30px;
}

#product_detail .syousai-list .list-break .lists {
  width: 300px;
  margin-right: 35px;
  margin-bottom: 18px;
  display: inline-block;
  text-align: center;
}

#product_detail .syousai-list .list-break .lists img {
  border: 1px solid #fff;
}

#product_detail .syousai-list .list-break .lists:nth-child(even) {
  margin-right: 0;
}

/* Tab */
.tab-list {
  padding: 0 11px;
  border-bottom: 1px solid #fff;
  margin-bottom: 25px;
}

.tab-list::after {
  content: "";
  display: table;
  clear: both;
}

.tab-item {
  width: 246px;
  float: left;
  box-sizing: border-box;
  text-align: center;
  border: 1px solid #fff;
  border-bottom: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.tab-btn {
  background: #f6f6f6;
  background: -moz-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFF), color-stop(100%, #f6f6f6));
  background: -webkit-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: -o-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: -ms-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: linear-gradient(to bottom, #FFF 0%, #f6f6f6 100%);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  display: block;
  color: #999;
  cursor: pointer;
  padding: 12px 0;
}

.tab-btn:hover {
  text-decoration: none;
}

.tab-btn.is-active {
  background: #fff;
  color: #444;
  position: relative;
}

.tab-btn.is-active::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #fff;
  position: absolute;
  bottom: -1px;
  z-index: 2;
}

.tab-content {
  display: none;
  margin-bottom: 40px;
}

.tab-content.is-active {
  display: block;
}

/* Step */
.step-list {
  margin: 0 13px;
}

.step-item {
  position: relative;
  padding: 0 0 70px 110px;
  background: url(guide/icn_arrow.png) no-repeat center bottom 10px;
  background-size: 30px;
}

.step-item::after {
  content: "";
  display: table;
  clear: both;
}

.step-item:last-child {
  background: none;
}

.step-item::before {
  content: '';
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 0;
  left: 0;
}

.step-item:last-child .step-img:after {
  display: none;
}

.step-item01::before {
  background: url(guide/icn_step01.png) 0 0/80px no-repeat;
}

.step-item02::before {
  background: url(guide/icn_step02.png) 0 0/80px no-repeat;
}

.step-item03::before {
  background: url(guide/icn_step03.png) 0 0/80px no-repeat;
}

.step-item04::before {
  background: url(guide/icn_step04.png) 0 0/80px no-repeat;
}

.step-item05::before {
  background: url(guide/icn_step05.png) 0 0/80px no-repeat;
}

.step-item06::before {
  background: url(guide/icn_step06.png) 0 0/80px no-repeat;
}

.step-item07::before {
  background: url(guide/icn_step07.png) 0 0/80px no-repeat;
}

.step-box {
  width: 290px;
  float: left;
  line-height: 1.6;
}

.step-straight .step-box {
  float: none;
  width: auto;
}

.step-txt {
  margin-bottom: 25px;
}

.step-attention {
  color: #f00;
}

.step-note {
  color: #999;
  font-size: 13px;
  font-size: 1.3rem;
}

.step-img {
  width: 300px;
  float: right;
  /*
        &:after {
            content: '';
            display: block;
            position: absolute;
            background: url(kyuji/icn_step06.png) 0 0/30px no-repeat;
            width: 30px;
            height: 21px;
            bottom: -45px;
            right: 0;
            left: 0;
            margin: auto;;
        }
        */
}

.step-bottomArea {
  width: 100%;
  margin-bottom: 60px;
  line-height: 1.6;
}

.step-bottomArea p + p {
  margin-top: 10px;
}

/* Works */
.works-tit {
  padding-left: 36px;
  margin-bottom: 25px;
}

.works-box {
  width: 670px;
  background: #f6f6f6;
  padding: 37px 32px 0;
  margin: -35px auto 40px;
}

.works-box::after {
  content: "";
  display: table;
  clear: both;
}

.works-item {
  width: 206px;
  float: left;
  margin: 0 0 15px 26px;
}

.works-item:nth-child(3n+1) {
  margin-left: 0;
}

.works-img {
  margin-bottom: 10px;
}

/* Flow */
.flow-list {
  width: 734px;
  margin: 0 auto 35px;
}

.flow-item {
  margin-bottom: 30px;
}

.flow-item::after {
  content: "";
  display: table;
  clear: both;
}

.flow-item:last-child {
  margin-bottom: 0;
}

.flow-tit {
  border: 1px solid #fff;
  text-align: center;
  font-size: 19px;
  font-size: 1.9rem;
  box-sizing: border-box;
  width: 106px;
  float: left;
  color: #444;
  padding: 14px 0 16px;
  margin-right: 24px;
}

.flow-num {
  font-size: 31px;
  font-size: 3.1rem;
}

.flow-desc {
  width: 336px;
  float: left;
}

.flow-img {
  width: 250px;
  float: right;
}

/* Sample */
.sample-list {
  width: 734px;
  margin: 0 auto 50px;
}

.sample-list::after {
  content: "";
  display: table;
  clear: both;
}

.sample-item {
  width: 164px;
  float: left;
  margin: 0 0 9px 25px;
}

.sample-item:nth-child(4n+1) {
  margin-left: 0;
}

.sample-img {
  box-sizing: border-box;
  border: 1px solid #fff;
  text-align: center;
  padding: 40px 0;
  margin-bottom: 9px;
}

.sample-name {
  min-height: 42px;
}

/* Card */
.card-list {
  margin: 0 13px;
  margin-bottom: 40px;
}

.card-list::after {
  content: "";
  display: table;
  clear: both;
}

.card-item {
  width: 354px;
  float: left;
  box-sizing: border-box;
  padding: 14px 17px;
  border: 1px solid #fff;
  margin: 0 0 22px 26px;
}

.card-item::after {
  content: "";
  display: table;
  clear: both;
}

.card-item:nth-child(odd) {
  margin-left: 0;
}

.card-item:nth-child(5), .card-item:nth-child(6) {
  margin-bottom: 0;
}

.card-box {
  width: 173px;
  float: right;
  padding-top: 10px;
}

.card-tit {
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.card-desc {
  font-size: 13px;
  font-size: 1.3rem;
}

.card-img {
  width: 110px;
  float: left;
}

/* Youtube */
.youtube-wrap {
  width: 640px;
  margin: 0 auto;
  background: #f6f6f6;
  margin-bottom: 48px;
}

/* Career */
.career-box {
  background: #f6f6f6;
  margin: 0 13px 68px;
  padding: 36px 46px;
}

.career-tit {
  font-weight: bold;
  margin-bottom: 10px;
}

.career-list {
  line-height: 2.5;
}

.career-list::after {
  content: "";
  display: table;
  clear: both;
}

.career-date {
  font-weight: bold;
  clear: both;
  width: 95px;
  float: left;
  margin-right: 20px;
}

.career-desc {
  width: 527px;
  float: left;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.modal-overlay.is-open {
  display: block;
}

.modal-inner {
  width: 500px;
  height: 500px;
  padding: 50px;
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.modal-close::before, .modal-close::after {
  content: '';
  position: absolute;
  width: 27px;
  height: 1px;
  background: #000;
  top: 14px;
}

.modal-close::before {
  transform: rotate(45deg);
}

.modal-close::after {
  transform: rotate(-45deg);
}

/* Notification */
.notification-box {
  background: #f6f6f6;
  padding: 22px 26px 22px 46px;
  margin-bottom: 50px;
}

.notification-tit {
  color: #F00;
  font-size: 15px;
  font-size: 1.5rem;
  position: relative;
  margin-bottom: 10px;
}

.notification-tit::before {
  content: '!';
  font-size: 26px;
  font-size: 2.6rem;
  line-height: 1;
  margin: 0 12px 0 2px;
  display: inline-block;
  font-weight: bold;
}

.notification-attention {
  color: #F00;
}

.notification-list {
  line-height: 1.7;
}

.notification-item {
  padding-left: 1em;
  text-indent: -1em;
}

/* Faq */
.faq-btn {
  background: #f6f6f6;
  background: -moz-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFF), color-stop(100%, #f6f6f6));
  background: -webkit-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: -o-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: -ms-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: linear-gradient(to bottom, #FFF 0%, #f6f6f6 100%);
  color: #444;
  font-size: 1.6rem;
  display: block;
  padding: 23px 0;
}

.faq-btn:hover {
  text-decoration: none;
}

.faq-btn-list {
  margin: 0 13px 30px 13px;
  display: flex;
}

.faq-btn-list::after {
  content: "";
  display: table;
  clear: both;
}

.faq-btn-item {
  width: 50%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  overflow: hidden;
  margin-left: 25px;
}

.faq-btn-item:first-child {
  margin-left: 0;
}

.faq-tit {
  color: #444;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 9px 0 10px 13px;
  margin: 0 13px;
}

.faq-list {
  line-height: 1.6;
  margin: 0 13px;
}

.faq-question {
  position: relative;
  border-top: 1px dotted #ccc;
  padding: 48px 78px;
  cursor: pointer;
}

.faq-question:first-child {
  border-top: none;
}

.faq-question::before, .faq-question::after {
  content: '';
  display: block;
  position: absolute;
}

.faq-question::before {
  width: 20px;
  height: 28px;
  background: url(guide/icn_faq01.png) 0 0/20px no-repeat;
  top: 48px;
  left: 32px;
}

.faq-question::after {
  width: 18px;
  height: 18px;
  border-bottom: 1px solid #444;
  border-right: 1px solid #444;
  transform: rotate(45deg);
  top: 48px;
  right: 17px;
  transition: .4s;
}

.faq-question.is-open::after {
  top: 58px;
  transform: rotate(225deg);
}

.faq-answer {
  color: #94b9b6;
  position: relative;
  padding: 0 78px 48px;
  display: none;
}

.faq-answer.is-open {
  display: block;
}

.faq-answer::before {
  content: '';
  display: block;
  width: 20px;
  height: 28px;
  background: url(guide/icn_faq02.png) 0 0/20px no-repeat;
  position: absolute;
  top: 0;
  left: 32px;
}

.faq-answer a {
  color: #94b9b6;
  text-decoration: underline;
}

.faq-marker {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), to(#ffc9d3));
  background: -o-linear-gradient(transparent 60%, #ffc9d3);
  background: linear-gradient(transparent 60%, #ffc9d3);
  color: #808080;
}

.faq-redTxet {
  color: #808080;
}

/* Payment */
.payment-list {
  margin-bottom: 15px;
}

.payment-list::after {
  content: "";
  display: table;
  clear: both;
}

.payment-item {
  float: left;
}

.payment-item-card {
  width: 65px;
  margin-left: 5px;
}

.payment-item-card:first-child {
  margin-left: 0;
}

.payment-item-time {
  width: 60px;
  margin-left: 10px;
}

.payment-item-time:first-child {
  margin-left: 0;
}

.payment-txt {
  margin-bottom: 10px;
}

/* Privacy */
.privacy-box {
  margin: 0 13px 30px;
}

.privacy-link {
  text-decoration: underline;
}

.privacy-link:hover {
  text-decoration: none;
}

/* Contact */
.contact-table {
  width: 734px;
  margin: 0 13px 14px;
}

.contact-table th {
  vertical-align: top;
  box-sizing: border-box;
  padding: 28px 0 0 22px;
  width: 190px;
}

.contact-table td {
  padding: 14px 0;
}

.contact-input[type=text], .contact-textarea {
  background: #f6f6f6;
  border: none;
}

.contact-input[type=text]:focus, .contact-textarea:focus {
  outline: none;
}

.contact-input[type=text].contact-error, .contact-textarea.contact-error {
  color: #444;
}

.contact-input[type=text] {
  width: 360px;
}

.contact-textarea {
  width: 545px;
  height: 140px;
}

.contact-btn {
  width: 170px;
  margin-left: 203px;
}

.contact-btn input {
  width: 100%;
}

.contact-error {
  display: block;
  color: #f00;
}

/* Xstamper */
/* Tebori */
/* Tegaki */
#category_list #tegaki_detail .description {
  margin: 0 15px 40px;
}

#category_list #tegaki_detail .description a {
  text-decoration: underline;
}

#category_list #tegaki_detail .description a:hover {
  text-decoration: none;
}

#category_list #tegaki_detail .craftsman_part .craftsman_dec {
  margin-bottom: 40px;
}

#category_list #tegaki_detail .craftsman_part .craftsman_dec::after {
  content: "";
  display: table;
  clear: both;
}

#category_list #tegaki_detail .craftsman_part .craftsman_dec .comment {
  padding: 0 15px 20px 15px;
  width: 380px;
  float: left;
}

#category_list #tegaki_detail .craftsman_part .craftsman_dec .comment p {
  margin-top: 1.2em;
}

#category_list #tegaki_detail .craftsman_part .craftsman_dec .comment p:first-child {
  margin-top: 0;
}

#category_list #tegaki_detail .craftsman_part .craftsman_dec .thumbnail {
  width: 300px;
  margin: 0 15px 20px;
  float: right;
}

#category_list #tegaki_detail .craftsman_part .craftsman_dec .thumbnail img {
  width: 100%;
}

#category_list #tegaki_detail .craftsman_part .example_list {
  margin: 27px 15px;
  background: #f6f6f6;
  box-sizing: border-box;
  padding: 32px 32px 10px;
  position: relative;
}

#category_list #tegaki_detail .craftsman_part .example_list::after {
  content: "";
  display: table;
  clear: both;
}

#category_list #tegaki_detail .craftsman_part .example_list .title2 {
  top: -8px;
  position: absolute;
}

#category_list #tegaki_detail .craftsman_part .example_list .lists {
  float: left;
  width: 206px;
  margin-right: 24px;
}

#category_list #tegaki_detail .craftsman_part .example_list .lists:nth-child(3n+1) {
  margin-right: 0;
}

#category_list #tegaki_detail .craftsman_part .example_list .lists .caption {
  margin: 10px 0 15px;
}

#category_list #tegaki_detail .handseal_part .note {
  margin-bottom: 15px;
}

#category_list #tegaki_detail .handseal_part .step {
  margin-left: 10px;
}

#category_list #tegaki_detail .handseal_part .step li {
  position: relative;
  float: left;
  margin-right: 70px;
  width: 125px;
}

#category_list #tegaki_detail .handseal_part .step li::after {
  content: ' ';
  position: absolute;
  top: 56px;
  right: -50px;
  width: 28px;
  height: 14px;
  background: url(tegaki/step_arrow.png) no-repeat center center;
  background-size: 100%;
}

#category_list #tegaki_detail .handseal_part .step li:last-child {
  margin-right: 0;
}

#category_list #tegaki_detail .handseal_part .step li:last-child::after {
  display: none;
}

#category_list #tegaki_detail .handseal_part .step li figure img {
  border: solid #fff 1px;
  width: 123px;
}

#category_list #tegaki_detail .handseal_part .step li figure figcaption {
  padding: 5px 1px 0;
}

#category_list #tegaki_detail .handseal_part .sample {
  margin-top: 48px;
}

#category_list #tegaki_detail .handseal_part .sample .sub_title {
  margin-bottom: 30px;
  padding: 5px 10px;
  background: #f6f6f6;
  font-size: 15px;
  font-size: 1.5rem;
}

#category_list #tegaki_detail .handseal_part .sample ul {
  margin-left: 10px;
}

#category_list #tegaki_detail .handseal_part .sample ul li {
  float: left;
  margin-left: 16px;
  width: 78px;
}

#category_list #tegaki_detail .handseal_part .sample ul li:first-child {
  margin-left: 0;
}

#category_list #tegaki_detail .handseal_part .sample ul li img {
  width: 100%;
}

#category_list #tegaki_detail .handwritten_part .description {
  margin: 0 15px 24px;
}

#category_list #tegaki_detail .handwritten_part .handwritten_list {
  margin: 0px 15px;
  box-sizing: border-box;
  position: relative;
}

#category_list #tegaki_detail .handwritten_part .handwritten_list::after {
  content: "";
  display: table;
  clear: both;
}

#category_list #tegaki_detail .handwritten_part .handwritten_list .lists {
  float: left;
  width: 164px;
  margin-right: 24px;
}

#category_list #tegaki_detail .handwritten_part .handwritten_list .lists:nth-child(3n+1) {
  clear: none;
}

#category_list #tegaki_detail .handwritten_part .handwritten_list .lists:nth-child(4n) {
  margin-right: 0;
}

#category_list #tegaki_detail .handwritten_part .handwritten_list .lists img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: 1px solid #fff;
  vertical-align: top;
}

#category_list #tegaki_detail .handwritten_part .handwritten_list .lists .caption {
  margin: 10px 0;
}

#category_list #tegaki_detail .banner-bottom {
  margin-top: 50px;
  text-align: center;
}

#category_list #tegaki_detail .banner-bottom a {
  display: inline-block;
  vertical-align: top;
}

.page-txtArea .page-txt.is-bold {
  font-weight: 700;
  font-size: 16px;
}

#tebori_detail .theme-heading01 {
  margin-bottom: 25px;
}

/* Kaisyagaiyou */
#kaisyagaiyou_detail .banner-top {
  margin-bottom: 30px;
}

#kaisyagaiyou_detail .banner-top a {
  display: block;
}

#kaisyagaiyou_detail .company_profile .description {
  margin: 0 15px 24px;
}

#kaisyagaiyou_detail .company_profile table {
  border-collapse: collapse;
  width: 100%;
}

#kaisyagaiyou_detail .company_profile table tr td {
  border: 1px solid #fff;
  padding: 1.7em 1.5em;
  box-sizing: border-box;
}

#kaisyagaiyou_detail .company_profile table tr td:first-child {
  width: 145px;
  font-weight: bold;
  background: #f6f6f6;
}

#kaisyagaiyou_detail .company_profile table tr td:last-child {
  background: #fff;
  font-weight: normal;
}

#kaisyagaiyou_detail .company_profile table tr td a {
  text-decoration: underline;
}

/* Category List */
#category_list .banner-top::after {
  content: "";
  display: table;
  clear: both;
}

#category_list .banner-top .description {
  padding: 25px 15px 40px;
}

#category_list .banner-top .title {
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1;
  background: #f6f6f6;
  padding: 12px 15px;
}

#category_list .typeface-list {
  margin-bottom: 50px;
}

#category_list .typeface-list::after {
  content: "";
  display: table;
  clear: both;
}

#category_list .typeface-list .lists {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

#category_list .typeface-list .lists thead tr th {
  border: 1px solid #fff;
  background: #f6f6f6;
  padding: 1em 0;
  text-align: center;
  width: 20%;
  font-size: 15px;
  font-size: 1.5rem;
}

#category_list .typeface-list .lists thead tr th span {
  font-size: 12px;
  font-size: 1.2rem;
}

#category_list .typeface-list .lists tbody tr td {
  border: 1px solid #fff;
  padding: 0.3em 0;
  text-align: center;
}

#category_list .size-guide {
  margin-bottom: 50px;
}

#category_list .size-guide::after {
  content: "";
  display: table;
  clear: both;
}

#category_list .size-guide .title {
  display: table;
  line-height: 1;
  height: 30px;
  border-left: 4px solid #444;
}

#category_list .size-guide .title .tb-1 {
  font-size: 21px;
  font-size: 2.1rem;
  letter-spacing: 3px;
  padding-left: 15px;
  display: table-cell;
  vertical-align: middle;
}

#category_list .size-guide .guide-table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

#category_list .size-guide .guide-table tr:first-child td {
  background: #f6f6f6;
}

#category_list .size-guide .guide-table tr td {
  width: 14.1666667%;
  border: 1px solid #fff;
  padding: 2em 0;
  text-align: center;
}

#category_list .size-guide .guide-table tr td:first-child {
  background: #f6f6f6;
  width: 15%;
}

#category_list .size-guide .guide-table tr td span {
  font-size: 12px;
  font-size: 1.2rem;
}

#category_list .product-list .title {
  display: table;
  padding-left: 15px;
  line-height: 1.2;
  height: 30px;
  border-left: 4px solid #444;
}

#category_list .product-list .title .tb-1 {
  font-size: 21px;
  font-size: 2.1rem;
  letter-spacing: 3px;
  padding-left: 15px;
  display: table-cell;
  vertical-align: middle;
}

#category_list .product-list .break-list::after {
  content: "";
  display: table;
  clear: both;
}

#category_list .product-list .break-list .lists {
  float: left;
  margin: 0 20px 40px 0;
  width: 240px;
}

#category_list .product-list .break-list .lists:nth-of-type(3n) {
  margin-right: 0;
}

#category_list .product-list .break-list .lists:nth-of-type(3n+1) {
  clear: both;
}

#category_list .product-list .break-list .lists .thumbnail .product-pic {
  width: 100%;
  height: 240px;
  border: 1px solid #fff;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
  box-sizing: border-box;
}

#category_list .product-list .break-list .lists .thumbnail .product-pic img {
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#category_list .product-list .break-list .lists .thumbnail .text {
  font-size: 16px;
  font-size: 1.6rem;
}

#category_list .product-list .break-list .lists .thumbnail .text .price {
  color: #ff0000;
  margin-top: 30px;
}

#category_list .product-list .break-list .lists .thumbnail a {
  display: block;
  text-decoration: none;
}

#category_list .product-list .break-list .lists .shipping {
  display: block;
  padding: 10px 0;
  line-height: 1;
}

#category_list .product-list .break-list .lists .shipping li {
  display: inline-block;
  margin-right: 4px;
}

#category_list .product-list .break-list .lists .shipping li:last-child {
  margin-right: 0;
}

#category_list .product-list .break-list .lists .box-basket {
  width: 100%;
  display: table;
  background: #f6f6f6;
  padding: 10px 14px;
  margin-top: 15px;
  text-align: right;
  box-sizing: border-box;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

#category_list .product-list .break-list .lists .box-basket .side-input {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

#category_list .product-list .break-list .lists .box-basket .side-input input {
  height: 24px;
  line-height: 24px;
  box-sizing: border-box;
  padding: 4px 10px;
  margin: 0 10px 0 5px;
  width: 40px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 0;
}

#category_list .product-list .break-list .lists .box-basket .side-basket {
  display: table-cell;
  vertical-align: middle;
}

#category_list .banner-bottom {
  text-align: center;
  margin-top: 100px;
}

#category_list .banner-bottom a {
  display: block;
}

/* Category Detail */
#category_detail .banner-top .tab {
  margin-bottom: 30px;
  padding: 0 13px;
  border-bottom: solid #fff 1px;
  text-align: center;
}

#category_detail .banner-top .tab::after {
  content: "";
  display: table;
  clear: both;
}

#category_detail .banner-top .tab li {
  position: relative;
  float: left;
  margin-left: -1px;
  width: 244px;
  font-size: 14px;
  font-size: 1.4rem;
}

#category_detail .banner-top .tab li:first-child {
  margin-left: 0;
}

#category_detail .banner-top .tab li a {
  display: block;
  position: relative;
  border: solid #fff 1px;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}

#category_detail .banner-top .tab li a span {
  display: block;
  padding: 12px 0;
  background: #f6f6f6;
  background: -moz-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #FFF), color-stop(100%, #f6f6f6));
  background: -webkit-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: -o-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: -ms-linear-gradient(top, #FFF 0%, #f6f6f6 100%);
  background: linear-gradient(to bottom, #FFF 0%, #f6f6f6 100%);
  border: solid #fff 1px;
  border-bottom: none;
}

#category_detail .banner-top .tab li a:hover, #category_detail .banner-top .tab li a.on {
  margin-top: -12px;
  padding-top: 12px;
  text-decoration: none;
}

#category_detail .banner-top .tab li a:hover span, #category_detail .banner-top .tab li a.on span {
  background: #fff;
}

#category_detail .banner-top .description {
  margin-bottom: 30px;
  padding: 0;
  font-size: 1.4rem;
}

#category_detail .block {
  display: none;
}

#category_detail .block-notab {
  display: block;
}

#category_detail .typeface-list {
  margin-bottom: 50px;
}

#category_detail .typeface-list .lists {
  float: none !important;
  border-collapse: collapse;
  width: 100% !important;
  margin: 20px 0 0 !important;
}

#category_detail .typeface-list .lists thead tr th {
  border: 1px solid #fff;
  background: #f6f6f6;
  padding: 1em 0;
  text-align: center;
  width: 126px;
  font-size: 15px;
  font-size: 1.5rem;
}

#category_detail .typeface-list .lists thead tr th span {
  font-size: 12px;
  font-size: 1.2rem;
}

#category_detail .typeface-list .lists tbody tr th, #category_detail .typeface-list .lists tbody tr td {
  border: 1px solid #fff;
  padding: 0.3em 0;
  text-align: center;
}

#category_detail .typeface-list .lists tbody tr th {
  background: #f6f6f6;
}

#category_detail .typeface-list .lists tbody tr.bd-none td {
  border-bottom: none;
}

#category_detail .typeface-list .lists tbody tr.bd-none + tr td {
  border-top: none;
}

#category_detail .typeface-list .is-short {
  width: 540px !important;
}

#category_detail .typeface-list .is-short-more {
  width: 400px !important;
}

#category_detail .size-guide {
  margin-bottom: 50px;
}

#category_detail .size-guide .guide-table {
  border-collapse: collapse;
  margin-top: 20px;
}

#category_detail .size-guide .guide-table tr:first-child td {
  background: #f6f6f6;
}

#category_detail .size-guide .guide-table tr td {
  width: 14.1666667%;
  border: 1px solid #fff;
  padding: 2em 0;
  text-align: center;
  width: 110px;
}

#category_detail .size-guide .guide-table tr td:first-child {
  background: #f6f6f6;
}

#category_detail .size-guide .guide-table tr td span {
  font-size: 12px;
  font-size: 1.2rem;
}

#category_detail .banner-bottom {
  margin-top: 50px;
  text-align: center;
}

#category_detail .banner-bottom a {
  display: inline-block;
  vertical-align: top;
}

/* Sitemap */
#sitemap_detail .sitemap_break {
  margin-bottom: 40px;
}

#sitemap_detail .sitemap_break::after {
  content: "";
  display: table;
  clear: both;
}

#sitemap_detail .sitemap_break .theme-heading01 {
  margin-bottom: 20px;
}

#sitemap_detail .sitemap_break .list {
  float: left;
  width: 370px;
}

#sitemap_detail .sitemap_break .list:nth-of-type(2n) {
  float: right;
}

#sitemap_detail .sitemap_break .list:nth-of-type(2n+1) {
  clear: both;
}

#sitemap_detail .sitemap_break .list .sub_title {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
  padding: 9px 10px;
  background: #f6f6f6;
}

#sitemap_detail .sitemap_break .list ul {
  display: block;
  vertical-align: top;
  margin-top: 5px;
}

#sitemap_detail .sitemap_break .list ul li a {
  text-decoration: none;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 22px;
  background: url(sitemap/icon-list.png) no-repeat left 2px center;
  padding-left: 10px;
}

#sitemap_detail .sitemap_break .nav-square {
  width: 100%;
  float: none;
}

#sitemap_detail .sitemap_break .nav-square-flex {
  display: flex;
}

#sitemap_detail .sitemap_break .nav-square-list {
  width: calc(50% - 10px);
}

#sitemap_detail .sitemap_break .nav-square-list:nth-of-type(2n) {
  padding-left: 20px;
}

table.top-tbl {
  width: auto;
  margin: 0 auto;
}

table.top-tbl td {
  text-align: center;
  padding: 8px;
}

div.subttl-box {
  width: auto;
  margin: 1.2em auto;
  font-weight: bold;
  font-size: 115%;
}

/* TOPナビ */
/* 情報ボックス */
div.news-box {
  width: 640px;
  margin: 1.2em auto;
  text-align: left;
  border-top: 1px solid #a99770;
  /* letter-spacing: 1px; */
  line-height: 160%;
  background: #fff;
}

div.news-ttl {
  background: #a99770;
  color: #fff;
  letter-spacing: 1px;
  font-weight: bold;
}

/* 商品購入カゴ */
table.item-tbl {
  margin: 0 auto;
  width: auto;
}

table.item-tbl td {
  padding: 3px;
}

table.item-tbl strong, table.detail-tbl strong {
  color: #ce6700;
  font-size: 115%;
}

table.item-tbl span, table.detail-tbl span {
  color: #808000;
}

.item-com {
  width: 330px;
  text-align: left;
}

.item-cart {
  width: 150px;
  text-align: center;
}

.item-tbl p {
  margin-top: 5px;
  font-size: 90%;
  color: #444;
}

table.item-tbl fieldset, table.detail-tbl fieldset {
  padding: 8px 3px;
  font-size: 90%;
  border: 1px solid #aaa;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

table.item-tbl legend, table.detail-tbl legend {
  color: #808000;
}

input.form-num {
  width: 60px;
  height: 20px;
  ime-mode: inactive;
  border: 1px solid #6f6042;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

td.pt-memo {
  color: #6c563c;
  font-size: 90%;
}

td.pt-memo b {
  color: #dd0000;
}

/* 詳細ページ */
table.detail-tbl {
  width: 640px;
}

table.detail-tbl td {
  width: 320px;
}

table.detail-tbl td.image {
  text-align: center;
  vertical-align: top;
  padding: 1.2em 0;
}

hr.dot-line {
  height: 2px;
  background-color: #f5b48f;
  border: none;
  color: #f5b48f;
  width: 95%;
  margin: 1.5em 0;
}

.item-comment {
  border-top: 2px solid #f5b48f;
  border-bottom: 2px solid #f5b48f;
  margin: 2em auto;
  padding: 1.2em 1em;
  width: 90%;
  text-align: left;
}

table.detail-tbl fieldset {
  width: 90%;
}

/* 会員フォーム */
/* 情報案内ページ */
table#note-tbl {
  border-collapse: collapse;
  margin: 1em auto;
}

table#note-tbl th, table#note-tbl td {
  padding: 6px;
  border: 1px solid #444;
  font-size: 90%;
}

table#note-tbl th {
  background: #ddd;
  white-space: nowrap;
}

table#note-tbl td {
  text-align: left;
  background: #fff;
}

/* ナビボックス */
div.box {
  background-color: #f5f4ef;
  color: #444;
  padding: 10px 14px;
  margin-bottom: 20px;
  text-align: center;
}

div.box-cat {
  border: 1px solid #ccc;
  padding: 20px 20px 0;
  margin-bottom: 25px;
}

div.navi-ttl {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

/* カテゴリリンク */
div.cate-in {
  padding: 8px 4px;
  font-size: 90%;
}

div.note-link {
  background: #fff;
  padding: 5px 0;
}

.side-entry {
  margin-bottom: 7px;
}

.side-search {
  border: 1px solid #ccc;
  width: 167px;
  margin: 0 auto;
}

.side-search::after {
  content: "";
  display: table;
  clear: both;
}

.side-search input {
  float: left;
  appearance: none;
  height: 24px;
  line-height: 24px;
  border: none;
  box-sizing: border-box;
}

.side-search input#find {
  width: 135px;
  background: #fff;
  border-right: 1px solid #ccc;
}

.side-search input[type='submit'] {
  width: 32px;
  background: url(btn_search.png) 0 0 no-repeat;
  font-size: 0;
  cursor: pointer;
}

.side-bnr {
  margin-bottom: 25px;
}

.side-list {
  text-align: left;
  margin-bottom: 10px;
}

.side-cat-tit {
  color: #444;
  margin-bottom: 10px;
  padding-left: 30px;
}

.side-cat-subtit {
  font-size: 1.3rem;
  background-color: #94b9b6;
  color: #fff;
  padding: 1px 0 1px 7px;
}

.side-cat-stamp {
  background: url(icn_stamp.png) 4px center no-repeat;
}

.side-cat-corporative {
  background: url(icn_corporative.png) 4px center no-repeat;
}

.side-cat-other {
  background: url(icn_other.png) 4px center no-repeat;
}

.side-item {
  font-size: 1.3rem;
  border-bottom: 1px dotted #ccc;
}

.side-item:last-child {
  border-bottom: none;
}

.side-item a {
  display: block;
  position: relative;
  padding: 6px 0 6px 20px;
  color: #777777;
}

.side-item a::before {
  content: '';
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%) rotate(45deg);
}

.box a {
  color: #444;
}

.side-hover > a::before {
  transform: translateY(-50%) rotate(135deg);
}

.side-hover > a.on::before {
  transform: translateY(-50%) rotate(-45deg);
}

.side-hover > ul {
  display: none;
}

.side-hover > ul li a::before {
  display: none;
}

.ttl-in-about {
  background: url(icn_about.png) 4px center no-repeat;
  padding-left: 15px;
}

.ttl-in-shopping {
  background: url(icn_shopping.png) 4px center no-repeat;
}

div.remind {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
}

/* ログイン */
div.login {
  margin: 8px 0 8px 12px;
}

div.login input[type="text"], div.login input[type="password"] {
  width: 165px;
  height: 20px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border: 1px solid #ffa66a;
}

/* カレンダ */
.calendar .ttl-in {
  color: #444;
}

table.calen {
  border-collapse: collapse;
  font-size: 1.3rem;
  color: #444;
  width: 170px;
  margin: 0 auto;
}

table.calen th, table.calen td {
  border-bottom: 1px solid #c0c0c0;
  padding: 4px;
  background: #fff;
  text-align: center;
}

tr.day td {
  color: #616161;
}

td.sun {
  color: #ff6262;
}

td.sat {
  color: #4d4dff;
}

div.calen-memo {
  color: #666;
  margin: 4px 0;
}

div.calen-memo span.hol {
  color: #ffa6b6;
}

div.calen-memo span.tod {
  color: #ff2d2d;
}

div.hol {
  background: #ffa6b6;
}

div.tod {
  border: 1px solid #ff2d2d;
}

div.calendar {
  background: #fff;
  border: 1px solid #ccc;
}

.calendar-month {
  text-align: center;
  font-weight: bold;
}

/* 正常・エラー画面 */
div#msg-box {
  margin: 2em 0 15em 2em;
  text-align: center;
  width: 480px;
  background: #fff;
  border: 1px solid #555;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  box-shadow: 0px 0px 4px 2px #cac4ac;
  -webkit-box-shadow: 0px 0px 4px 2px #cac4ac;
  -moz-box-shadow: 0px 0px 4px 2px #cac4ac;
}

div#msg-box p {
  margin: 1.2em;
}

div#err-box {
  margin: 1em auto;
  width: 400px;
  text-align: left;
  color: #ff3c3c;
}

div#com-box {
  margin: 1em auto;
  width: 400px;
  text-align: center;
  color: #006a00;
}

div.code {
  color: #ff6317;
  font-size: 90%;
  margin: 0 0 2px 0;
}

div.cart-btn img {
  margin: 1.2em 2em;
}

input.ct-num {
  width: 25px;
  ime-mode: inactive;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border: 1px solid #555;
}

input.chg-btn {
  padding: 4px 2px;
  font-size: 85%;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border: 1px solid #555;
}

/* リサイズ画像 */
img.resize {
  display: none;
  border: none;
}

/* ボタン */
img.btn {
  border: none;
}

/* アイコン */
.icon {
  vertical-align: middle;
  border: none;
}

/* 画像マウスオーバエフェクト */
a:hover img, input[type="image"]:hover {
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}

/* 赤文字 */
.red {
  color: #dd0000;
}

/* 寄せ */
.ta-c {
  text-align: center !important;
}

.ta-l {
  text-align: left !important;
}

.ta-r {
  text-align: right !important;
}

#main > .ta-c {
  text-align: left !important;
  font-size: 14px;
}

#main > .ta-c img {
  margin-top: 20px;
}

/*
	参考 http://weboook.blog22.fc2.com/blog-entry-329.html
*/
table.cart-tbl {
  margin: 1em auto;
  width: auto;
  border-spacing: 0;
}

table.cart-tbl th {
  color: #000;
  padding: 8px 15px;
  background: #eee;
  background: -moz-linear-gradient(#eee, #ddd 50%);
  background: -webkit-gradient(linear, 100% 0%, 100% 50%, from(#eee), to(#ddd));
  font-weight: bold;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  line-height: 120%;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.9);
  box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3) inset;
}

table.cart-tbl th:first-child {
  border-left: 1px solid #aaa;
  border-radius: 5px 0 0 0;
}

table.cart-tbl th:last-child {
  border-radius: 0 5px 0 0;
  border-right: 1px solid #aaa;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
}

table.cart-tbl tr td {
  padding: 8px 15px;
  text-align: center;
}

table.cart-tbl tr td:first-child {
  border-left: 1px solid #aaa;
}

table.cart-tbl tr td:last-child {
  border-right: 1px solid #aaa;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
}

table.cart-tbl tr {
  background: #fff;
}

table.cart-tbl tr:nth-child(2n+1) {
  background: #f5f5f5;
}

table.cart-tbl tr:last-child td {
  border-bottom: 1px solid #aaa;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
}

table.cart-tbl tr:last-child td:first-child {
  border-radius: 0 0 0 5px;
}

table.cart-tbl tr:last-child td:last-child {
  border-radius: 0 0 5px 0;
}

table.cart-tbl tr:hover {
  background: #eee;
  cursor: pointer;
}

/* 確認画面 */
.w-item {
  width: 320px !important;
}

/* 購入履歴・問い合わせフォーム */
p.ttl-history {
  width: 590px !important;
  margin: 18px auto 8px auto;
  text-align: left;
  font-weight: bold;
}

.f-12 {
  font-size: 12px;
}

.col-brown {
  color: #80410d;
}

/* 注文者情報 */
table.prog-tbl td span {
  font-size: 12px;
  color: #006a00;
}

td.login-form {
  width: 280px !important;
}

fieldset.user-meth {
  padding: 8px 12px;
  border: 1px solid #aaa;
  line-height: 150%;
}

fieldset.user-meth legend {
  color: #006a00;
  font-size: 12px;
}

/* フォーム長 */
input.w-240 {
  width: 240px;
  border: 1px solid #666;
  padding: 4px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

input.w-120 {
  width: 120px;
  border: 1px solid #666;
  padding: 4px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

input.w-40 {
  width: 40px;
  border: 1px solid #666;
  padding: 4px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

textarea.w-ta {
  width: 320px;
  height: 3em;
  border: 1px solid #666;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

/* 注文方法選択 */
table.prog-tbl div.ord-sel {
  margin: 4px 0 4px 15px;
}

/* 現在のポイント */
div.pt-now {
  margin-bottom: 4px;
  color: #006f00;
}

/* メルマガ・検索窓 */
#mailmag, #find {
  width: 110px;
  padding: 4px 2px;
}

/*  ページャー*/
ul.pager {
  clear: both;
  margin: 0 0 10px;
  padding: 10px 10px 5px;
}

ul.pager li {
  display: inline;
  margin: 0 2px;
  padding: 0;
}

ul.pager li span {
  display: inline-block;
  margin-bottom: 5px;
  padding: 3px 8px;
  background: #94b9b6;
  color: #fff;
  border: 1px solid #94b9b6;
  text-decoration: none;
  vertical-align: middle;
}

ul.pager li a {
  display: inline-block;
  margin-bottom: 5px;
  padding: 3px 8px;
  background: #fff;
  color: #444;
  border: 1px solid #94b9b6;
  text-decoration: none;
  vertical-align: middle;
}

ul.pager li a:hover {
  background: #ffc488;
}

.inei-empty {
  display: block;
  margin: 5px auto;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #f6f6f6;
}

/* nyukou */
.nyukou-intro {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 78px;
  background: url(nyukou/nyukou_border.png) repeat top left transparent;
  background-size: 23px auto;
}

.nyukou-intro-inner {
  box-sizing: border-box;
  width: 100%;
  padding: 36px 40px;
  border: solid 5px #94b9b6;
}

.nyukou-intro-text {
  margin-bottom: 35px;
  font-size: 1.4rem;
  line-height: 1.8;
  letter-spacing: 1px;
}

.nyukou-intro-title {
  margin-bottom: 28px;
}

.nyukou-intro-contents {
  display: flex;
  width: 100%;
}

.nyukou-intro-contents-img {
  display: block;
  width: 63px;
  padding: 0;
  margin: 0;
}

.nyukou-intro-contents-img img {
  display: block;
  width: 100%;
  height: auto;
}

.nyukou-intro-contents-body {
  width: calc(100% - 63px);
  padding-left: 12px;
}

.nyukou-intro-contents-body-text {
  font-size: 1.4rem;
  line-height: 1.8;
  letter-spacing: 1px;
}

.nyukou-intro-contents-body-text + .nyukou-intro-contents-body-text {
  margin-top: 10px;
}

.nyukou-intro-contents-body-text a {
  color: #94b9b6;
  font-weight: 500;
  letter-spacing: 2.5px;
}

.nyukou .step-item {
  background: none;
}

.nyukou .step-item .step-title {
  padding-left: 2.8rem;
  color: #94b9b6;
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: 1.2px;
  line-height: 1.8;
  text-indent: -2.8rem;
}

.nyukou .step-item .step-txt {
  margin-bottom: 10px;
  letter-spacing: 1.2px;
  line-height: 1.8;
}

.nyukou .step-item .step-txt.is-red {
  color: #ad4242;
}

.nyukou .step-item .step-box-ul {
  width: 100%;
  margin-top: 17px;
}

.nyukou .step-item .step-box-ul li {
  width: 100%;
  padding-left: 2.8rem;
  margin-top: 6px;
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: 2px;
  line-height: 1.6;
  text-indent: -2.8rem;
}

.nyukou .step-item:last-child {
  padding-bottom: 10px;
}

.nyukou .step-item01::before {
  background-image: url(nyukou/step1.png);
}

.nyukou .step-item02::before {
  background-image: url(nyukou/step2.png);
}

.nyukou .step-item03::before {
  background-image: url(nyukou/step3.png);
}

.nyukou .step-box {
  width: 550px;
}

.nyukou .step-img {
  width: 48px;
}

.nyukou-attention {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 74px;
  margin-bottom: 65px;
  background-color: #f5f4ef;
  border-radius: 10px;
  font-weight: bold;
  font-size: 1.6rem;
  letter-spacing: 2px;
}

.nyukou-attention .is-sp {
  display: none;
}

.nyukou .page-intro02 {
  letter-spacing: 2px;
  line-height: 1.8;
}

.nyukou-checkList {
  width: 100%;
}

.nyukou-checkList-item {
  width: 100%;
  margin-bottom: 30px;
}

.nyukou-checkList-title {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 0 0 0 35px;
  margin-bottom: 16px;
  background: url(nyukou/crayon.png) no-repeat top left transparent;
  background-size: 25px auto;
  letter-spacing: 1.2px;
  line-height: 1.8;
}

.nyukou-checkList-warning {
  box-sizing: border-box;
  display: flex;
  width: 100%;
}

.nyukou-checkList-warning .is-pre {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 30px;
  border-radius: 30px;
  color: #fff;
  font-size: 1.2rem;
  background-color: #94b9b6;
}

.nyukou-checkList-warning .is-main {
  box-sizing: border-box;
  display: block;
  width: calc(100% - 90px);
  padding-left: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 1.8;
}

.nyukou-checkList-warning .is-main.is-1row {
  padding-top: 5px;
}

/* デザインに合わせて調整 */
.theme-nyukou {
  margin-bottom: 47px;
}

/* aboutPage */
.aboutPage {
  margin-bottom: 70px;
}

.aboutPage .is-pc {
  display: block;
}

.aboutPage .is-sp {
  display: none;
}

.aboutPage .is-bold {
  font-weight: 500;
}

.aboutPage .is-big {
  color: #94b9b6;
  font-weight: 500;
  font-size: 1.8rem;
}

.aboutPage .theme-heading01 {
  margin-bottom: 30px !important;
}

.aboutPage-intro {
  margin-bottom: 70px;
}

.aboutPage-intro .page-txt {
  line-height: 1.8;
}

.aboutPage-text {
  padding: 10px 0;
  margin: 0 0 50px;
  font-size: 1.4rem;
  letter-spacing: 1px;
  line-height: 1.8;
}

.aboutPage-merit {
  display: flex;
  width: 100%;
  margin-bottom: 45px;
}

.aboutPage-merit-term {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 105px;
  height: 105px;
  border: 1px solid #ccc;
}

.aboutPage-merit-term .is-num {
  margin-bottom: 10px;
  font-weight: lighter;
  font-size: 3.6rem;
  line-height: 1;
}

.aboutPage-merit-term .is-text {
  font-size: 1.6rem;
  line-height: 1;
}

.aboutPage-merit-description {
  box-sizing: border-box;
  display: flex;
  width: calc(100% - 105px);
  padding: 0 0 0 20px;
  font-size: 1.4rem;
  letter-spacing: 1px;
  line-height: 1.8;
}

.aboutPage-merit-description-body {
  box-sizing: border-box;
  width: 59.4%;
  padding-right: 10px;
  line-height: normal;
}

.aboutPage-merit-description-img {
  box-sizing: border-box;
  display: block;
  width: 40.6%;
  padding: 3px;
  margin: 0;
  border: 1px solid #ccc;
}

.aboutPage-merit-description-img img {
  display: block;
  width: 100%;
  height: auto;
}

.aboutPage-idea {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}

.aboutPage-idea + .aboutPage-text {
  margin-top: 40px;
}

.aboutPage-idea-term {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 30px;
  border: 1px solid #94b9b6;
  border-radius: 30px;
  color: #94b9b6;
  font-size: 1.2rem;
  line-height: normal;
}

.aboutPage-idea-description {
  box-sizing: border-box;
  width: calc(100% - 90px);
  min-height: 27px;
  padding: 5px 0 0 45px;
  background: url(about/icon_idea.png) no-repeat top left 14px transparent;
  background-size: 25px auto;
  font-size: 1.4rem;
  line-height: 1.5;
}

.aboutPage-approach {
  box-sizing: border-box;
  width: 100%;
  padding: 30px 10px 40px;
  margin-bottom: 25px;
  background-color: #f5f4ef;
}

.aboutPage-approach-term {
  width: 100%;
  margin-bottom: 10px;
}

.aboutPage-approach-term .is-icon {
  display: block;
  width: 38px;
  margin: 0 auto 14px;
}

.aboutPage-approach-term .is-icon.approach02 {
  width: 21px;
}

.aboutPage-approach-term .is-icon.approach03 {
  width: 41px;
}

.aboutPage-approach-term .is-icon.approach04 {
  width: 37px;
}

.aboutPage-approach-term .is-icon img {
  display: block;
  width: 100%;
  height: auto;
}

.aboutPage-approach-term .is-title {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.5;
}

.aboutPage-approach-description {
  width: 100%;
  text-align: center;
  line-height: 1.6;
}

/* xstamperPage */
.xstamperPage-intro .page-list {
  margin-bottom: 30px;
}

.xstamperPage-intro .page-list-item::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #000;
  margin-right: 10px;
}

.xstamperPage-intro .is-link .is-nonBottomLine:hover {
  text-decoration: none;
}

.xstamperPage-intro .is-link .is-nonBottomLine .is-borderbtm {
  color: #94b9b6;
  border-bottom: solid 1px #94b9b6;
  padding-bottom: 5px;
}

.xstamperPage-intro .is-link .is-nonBottomLine .is-linkText {
  padding-left: 5px;
  color: #444;
}

/* inkChangePage */
.theme-inkPage {
  margin-bottom: 36px;
}

.inkPage-intro {
  margin-bottom: 40px;
}

.inkPage-intro-topText {
  margin-bottom: 44px;
}

.inkPage-intro-text-list-item {
  position: relative;
  display: block;
  padding-left: 10px;
}

.inkPage-intro-text-list-item::before {
  position: absolute;
  content: '';
  top: 12px;
  left: 0;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #333;
}

.inkPage-intro-warning {
  box-sizing: border-box;
  display: flex;
  width: 100%;
}

.inkPage-intro-warning .is-pre {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 30px;
  border-radius: 30px;
  color: #fff;
  font-size: 1.2rem;
  background-color: #94b9b6;
}

.inkPage-intro-warning-list {
  box-sizing: border-box;
  display: block;
  width: calc(100% - 90px);
  padding-left: 12px;
  font-weight: bold;
  letter-spacing: 1.2px;
  line-height: 1.8;
}

.inkPage-intro-warning-list-item {
  position: relative;
  display: block;
  padding-left: 10px;
}

.inkPage-intro-warning-list-item::before {
  position: absolute;
  content: '';
  top: 12px;
  left: 0;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: #333;
}

.inkPage-body {
  display: flex;
  width: 100%;
  margin-bottom: 40px;
}

.inkPage-body-title {
  width: 67.1%;
  padding-right: 25px;
}

.inkPage-body-title .is-title {
  color: #94b9b6;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
}

.inkPage-body-title .is-description {
  display: inline-block;
  padding-left: 3px;
}

.inkPage-body-image {
  box-sizing: border-box;
  display: block;
  padding: 3px;
  margin: 0;
  border: 1px solid #ccc;
}
