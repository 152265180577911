.faq {
    &-btn {
        @include gradient($themeGradientStart, $themeGradientEnd, $themeGradientOrientation);
        color: $shopColor;
        font-size: 1.6rem;
        display: block;
        padding: 23px 0;
        &:hover {
            text-decoration: none;
        }
        &-list {
            margin: 0 13px 30px 13px;
            @include clearfix;
            display: flex;
        }
        &-item {
            width: 50%;
            box-sizing: border-box;
            border: 1px solid $borderColorGray;
            border-radius: 5px;
            text-align: center;
            overflow: hidden;
            margin-left: 25px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    &-tit {
        color: $shopColor;
        border-top: 1px solid $borderColorGray;
        border-bottom: 1px solid $borderColorGray;
        padding: 9px 0 10px 13px;
        margin: 0 13px;
    }
    &-list {
        line-height: 1.6;
        margin: 0 13px;
    }
    &-question {
        position: relative;
        border-top: 1px dotted $borderColorGray;
        padding: 48px 78px;
        cursor: pointer;
        &:first-child {
            border-top: none;
        }
        &::before, &::after {
            content: '';
            display: block;
            position: absolute;
        }
        &::before {
            width: 20px;
            height: 28px;
            background: url(guide/icn_faq01.png) 0 0/20px no-repeat;
            top: 48px;
            left: 32px;
        }
        &::after {
            width: 18px;
            height: 18px;
            border-bottom: 1px solid $shopColor;
            border-right: 1px solid $shopColor;
            transform: rotate(45deg);
            top: 48px;
            right: 17px;
            transition: .4s;
        }
        &.is-open::after {
            top: 58px;
            transform: rotate(225deg);
        }
    }
    &-answer {
        color: $faqAnswer;
        position: relative;
        padding: 0 78px 48px;
        display: none;
        &.is-open {
            display: block;
        }
        &::before {
            content: '';
            display: block;
            width: 20px;
            height: 28px;
            background: url(guide/icn_faq02.png) 0 0/20px no-repeat;
            position: absolute;
            top: 0;
            left: 32px;
        }
        a {
            color: $faqAnswer;
            text-decoration: underline;
        }
    }
    &-marker {
        background: -webkit-gradient(linear, left top, left bottom, color-stop(60%, transparent), to(#ffc9d3));
        background: -o-linear-gradient(transparent 60%, #ffc9d3);
        background: linear-gradient(transparent 60%, #ffc9d3);
        color: #808080;
    }
    &-redTxet {
        color: #808080;
    }
}