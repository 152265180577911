.gnavi {
    // @include gradient($bkGradientStart, $bkGradientEndGnavi, 'vertical3step');
    background: #94b9b6;
    border-top: 1px solid $borderColor;
    &-list {
        width: $pc_w;
        margin: 0 auto;
        @include clearfix;
        display: flex;
        justify-content: center;
        height: 51px;
        .shachihata_text {
            letter-spacing: 0.45em;
        }
    }
    &-item {
        height: 100%;
        position: relative;
        &::before {
            content: "";
            display: block;
            width: 1px;
            height: 24px;
            background: $borderColor;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
        &:first-child::before {
            display: none;
        }
        a {
            height: 100%;
            display: block;
            padding: 15px 28px;
            color: #fff;
            box-sizing: border-box;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
