#category_list #tegaki_detail .description {
    margin: 0 15px 40px;
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
#category_list #tegaki_detail .craftsman_part .craftsman_dec {
    @include clearfix;
    margin-bottom: 40px;
    .comment {
        padding: 0 15px 20px 15px;
        width: 380px;
        float: left;
        p {
            margin-top: 1.2em;
            &:first-child {
                margin-top: 0;
            }
        }
    }
    .thumbnail {
        width: 300px;
        margin: 0 15px 20px;
        float: right;
        img {
            width: 100%;
        }
    }
}
#category_list #tegaki_detail .craftsman_part .example_list {
    margin: 27px 15px;
    background: $themeColor;
    box-sizing: border-box;
    padding: 32px 32px 10px;
    position: relative;
    @include clearfix;
    .title2 {
        top: -8px;
        position: absolute;
    }
    .lists {
        float: left;
        width: 206px;
        margin-right: 24px;
        &:nth-child(3n+1) {
            margin-right: 0;
        }
        .caption {
            margin: 10px 0 15px;
        }
    }
}
#category_list #tegaki_detail .handseal_part .note {
    margin-bottom: 15px;
}
#category_list #tegaki_detail .handseal_part .step {
    margin-left: 10px;
    li {
        position: relative;
        float: left;
        margin-right: 70px;
        width: 125px;
        &::after {
            content: ' ';
            position: absolute;
            top: 56px;
            right: -50px;
            width: 28px;
            height: 14px;
            background: url(tegaki/step_arrow.png) no-repeat center center;
            background-size: 100%;
        }
        &:last-child {
            margin-right: 0;
            &::after {
                display: none;
            }
        }
        figure {
            img {
                border: solid $borderColor 1px;
                width: 123px;
            }
            figcaption {
                padding: 5px 1px 0;
            }
        }
    }
}
#category_list #tegaki_detail .handseal_part .sample {
    margin-top: 48px;
    .sub_title {
        margin-bottom: 30px;
        padding: 5px 10px;
        background: $themeColor;
        font-size: 15px;
        font-size: 1.5rem;
    }
    ul {
        margin-left: 10px;
        li {
            float: left;
            margin-left: 16px;
            width: 78px;
            &:first-child {
                margin-left: 0;
            }
            img {
                width: 100%;
            }
        }
    }
}
#category_list #tegaki_detail .handwritten_part {
    .description {
        margin: 0 15px 24px;
    }
    .handwritten_list {
        margin: 0px 15px;
        box-sizing: border-box;
        position: relative;
        @include clearfix;
        .lists {
            float: left;
            width: 164px;
            margin-right: 24px;
            &:nth-child(3n+1) {
                clear: none;
            }
            &:nth-child(4n) {
                margin-right: 0;
            }
            img {
                width: auto;
                height: auto;
                max-width: 100%;
                border: 1px solid $borderColor;
                vertical-align: top;
            }
            .caption {
                margin: 10px 0;
            }
        }
    }
}
#category_list #tegaki_detail .banner-bottom {
    margin-top: 50px;
    text-align: center;
    a {
        display: inline-block;
        vertical-align: top;
    }
}
.page-txtArea {
    .page-txt {
        &.is-bold {
            font-weight: 700;
            font-size: 16px;
        }
    }
}
#tebori_detail {
    .theme-heading01 {
        margin-bottom: 25px;
    }
}