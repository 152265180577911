.theme-inkPage {
  margin-bottom: 36px;
}
.inkPage {
  &-intro {
    margin-bottom: 40px;
    &-topText {
      margin-bottom: 44px;
    }
      &-text {
        &-list {
          &-item {
            position: relative;
            display: block;
            padding-left: 10px;
            &::before {
              position: absolute;
              content: '';
              top: 12px;
              left: 0;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              background: #333;
            }
          }
        }
      }
      &-warning {
        box-sizing: border-box;
        display: flex;
        width: 100%;
        .is-pre {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90px;
          height: 30px;
          border-radius: 30px;
          color: #fff;
          font-size: 1.2rem;
          background-color: #94b9b6;
        }
        &-list {
          box-sizing: border-box;
          display: block;
          width: calc(100% - 90px);
          padding-left: 12px;
          font-weight: bold;
          letter-spacing: 1.2px;
          line-height: 1.8;
          &-item {
            position: relative;
            display: block;
            padding-left: 10px;
            &::before {
              position: absolute;
              content: '';
              top: 12px;
              left: 0;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              background: #333;
            }
          }
        }
      }
    }
    &-body {
      display: flex;
      width: 100%;
      margin-bottom: 40px;
      &-title {
        width: 67.1%;
        padding-right: 25px;
        .is-title {
          color: #94b9b6;
          font-size: 1.8rem;
          font-weight: bold;
          margin-bottom: 10px;
          display: block;
        }
        .is-description {
          display: inline-block;
          padding-left: 3px;
        }
      }
      &-image {
        box-sizing: border-box;
        display: block;
        padding: 3px;
        margin: 0;
        border: 1px solid #ccc;
      }
    }
}