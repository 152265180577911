.tab-list {
    padding: 0 11px;
    border-bottom: 1px solid $borderColor;
    margin-bottom: 25px;
    @include clearfix;
}
.tab-item {
    width: 246px;
    float: left;
    box-sizing: border-box;
    text-align: center;
    border: 1px solid $borderColor;
    border-bottom: none;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}
.tab-btn {
    @include gradient($themeGradientStart, $themeGradientEnd, $themeGradientOrientation);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    display: block;
    color: #999;
    cursor: pointer;
    padding: 12px 0;
    &:hover {
        text-decoration: none;
    }
    &.is-active {
        background: #fff;
        color: #444;
        position: relative;
    }
    &.is-active::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: #fff;
        position: absolute;
        bottom: -1px;
        z-index: 2;
    }
}
.tab-content {
    display: none;
    margin-bottom: 40px;
    &.is-active {
        display: block;
    }
}