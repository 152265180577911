#pagetop {
    width: 48px;
    height: 48px;
    z-index: 5;
    right: 20px;
    a {
        width: 48px;
        height: 48px;
        // background: $faqAnswer;
        background: $faqAnswer;
        display: block;
        text-align: center;
        position: relative;
        border-radius: 50%;
        &::before {
            content: "";
            position: absolute;
            width: 15px;
            height: 15px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            transform: rotate(-45deg);
            top: 20px;
            left: 0;
            right: 0;
            margin: auto;
        }
        &:hover::before {
            animation: pagetop 0.8s linear;
        }
    }
    &.is-static {
        position: absolute;
        top: -68px;
    }
    &.is-fixed {
        position: fixed;
        bottom: 20px;
    }
}
@keyframes pagetop {
    0% {
        transform: translateY(0) rotate(-45deg);
    }
    25% {
        transform: translateY(-2px) rotate(-45deg);
    }
    50% {
        transform: translateY(0) rotate(-45deg);
    }
    75% {
        transform: translateY(-2px) rotate(-45deg);
    }
    100% {
        transform: translateY(0) rotate(-45deg);
    }
}