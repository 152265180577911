#kaisyagaiyou_detail {
    .banner-top {
        margin-bottom: 30px;
        a {
            display: block;
        }
    }
    .company_profile {
        .description {
            margin: 0 15px 24px;
        }
        table {
            border-collapse: collapse;
            width: 100%;
            tr td {
                border: 1px solid $borderColor;
                padding: 1.7em 1.5em;
                box-sizing: border-box;
                &:first-child {
                    width: 145px;
                    font-weight: bold;
                    background: $themeColor;
                }
                &:last-child {
                    background: #fff;
                    font-weight: normal;
                }
                a {
                    text-decoration: underline;
                }
            }
        }
    }
}