.visual {
    padding-top: 17px;
    width: $pc_w;
    margin: 0 auto;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    transition: opacity .3s;
    height: 347px;
    @include clearfix;
    &.slick-slider {
        margin-bottom: 58px;
    }
    &.is-loading {
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
    &-item {
        width: $pc_w;
        float: left;
    }
}