#head {
    padding: 30px 0 0;
    margin-bottom: 13px;
    &-in {
        margin-bottom: 23px;
    }
    &-tbl {
        @include clearfix;
    }
}
.header {
    &-txt {
        font-size: 11px;
        font-size: 1.1rem;
        line-height: 27px;
    }
    &-logo {
        padding: 11px 0 7.5px 0;
    }
    &-list {
        width: 215px;
        float: left;
        padding-top: 4px;
        @include clearfix;
    }
    &-item {
        width: 100%;
    }
    &-btn {
        width: 265px;
        float: right;
        @include clearfix;
        &-item {
            width: 130px;
            float: left;
            margin-left: 5px;
            &:nth-child(odd) {
                margin-left: 0;
            }
            &:nth-child(-n+2) {
                margin-bottom: 5px;
            }
        }
    }
}