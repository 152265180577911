.xstamperPage {
  &-intro {
    .page-list {
      margin-bottom: 30px;
      &-item {
        &::before {
          content: "";
          display: inline-block;
          width: 10px;
          height: 10px;
          background-color: #000;
          margin-right: 10px;
        }
      }
    }
    .is-link {
      .is-nonBottomLine {
        &:hover {
          text-decoration: none;
        }
        .is-borderbtm {
          color: #94b9b6;
          border-bottom: solid 1px #94b9b6;
          padding-bottom: 5px;
        }
        .is-linkText {
          padding-left: 5px;
          color: $shopColor;
        }
      }
    }
  }
}
