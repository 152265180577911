#category_detail {
    .banner-top {
        .tab {
            margin-bottom: 30px;
            padding: 0 13px;
            border-bottom: solid $borderColor 1px;
            text-align: center;
            @include clearfix;
            li {
                position: relative;
                float: left;
                margin-left: -1px;
                width: 244px;
                font-size: 14px;
                font-size: 1.4rem;
                &:first-child {
                    margin-left: 0;
                }
                a {
                    display: block;
                    position: relative;
                    border: solid $borderColor 1px;
                    border-bottom: none;
                    border-radius: 8px 8px 0 0;
                    overflow: hidden;
                    span {
                        display: block;
                        padding: 12px 0;
                        @include gradient($themeGradientStart, $themeGradientEnd, $themeGradientOrientation);
                        border: solid #fff 1px;
                        border-bottom: none;
                    }
                    &:hover, &.on {
                        margin-top: -12px;
                        padding-top: 12px;
                        text-decoration: none;
                        span {
                            background: #fff;
                        }
                    }
                }
            }
        }
        .description {
            margin-bottom: 30px;
            padding: 0;
            font-size: 1.4rem;
        }
    }
    .block {
        display: none;
        &-notab {
            display: block;
        }
    }
    .typeface-list {
        margin-bottom: 50px;
        .lists {
            float: none !important;
            border-collapse: collapse;
            width: 100% !important;
            margin: 20px 0 0 !important;
            thead tr th {
                border: 1px solid $borderColor;
                background: $themeColor;
                padding: 1em 0;
                text-align: center;
                width: 126px;
                font-size: 15px;
                font-size: 1.5rem;
                span {
                    font-size: 12px;
                    font-size: 1.2rem;
                }
            }
            tbody tr {
                th, td {
                    border: 1px solid $borderColor;
                    padding: 0.3em 0;
                    text-align: center;
                }
                th {
                    background: $themeColor;
                }
                &.bd-none td {
                    border-bottom: none;
                }
                &.bd-none + tr td {
                    border-top: none;
                }
            }
        }
        .is-short {
            width: 540px!important;
            &-more {
                width: 400px!important;
            }
        }
    }
    .size-guide {
        margin-bottom: 50px;
        .guide-table {
            border-collapse: collapse;
            margin-top: 20px;
            tr {
                &:first-child td {
                    background: $themeColor;
                }
                td {
                    width: 14.1666667%;
                    border: 1px solid $borderColor;
                    padding: 2em 0;
                    text-align: center;
                    width: 110px;
                    &:first-child {
                        background: $themeColor;
                    }
                    span {
                        font-size: 12px;
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }
    .banner-bottom {
        margin-top: 50px;
        text-align: center;
        a {
            display: inline-block;
            vertical-align: top;
        }
    }
}