.step-list {
    margin: 0 13px;
}
.step-item {
    position: relative;
    padding: 0 0 70px 110px;
    background: url(guide/icn_arrow.png) no-repeat center bottom 10px;
    background-size: 30px;
    @include clearfix;
    &:last-child {
        background: none;
    }
    &::before {
        content: '';
        display: block;
        position: absolute;
        width: 80px;
        height: 80px;
        top: 0;
        left: 0;
    }
    &:last-child .step-img:after {
        display: none;
    }

}
.step-item {
    &01::before {
        background: url(guide/icn_step01.png) 0 0/80px no-repeat;
    }

    &02::before {
        background: url(guide/icn_step02.png) 0 0/80px no-repeat;
    }

    &03::before {
        background: url(guide/icn_step03.png) 0 0/80px no-repeat;
    }

    &04::before {
        background: url(guide/icn_step04.png) 0 0/80px no-repeat;
    }

    &05::before {
        background: url(guide/icn_step05.png) 0 0/80px no-repeat;
    }
    &06::before {
        background: url(guide/icn_step06.png) 0 0/80px no-repeat;
    }
    &07::before {
        background: url(guide/icn_step07.png) 0 0/80px no-repeat;
    }
}
.step-box {
    width: 290px;
    float: left;
    line-height: 1.6;
}
.step-straight .step-box {
    float: none;
    width: auto;
}
.step-txt {
    margin-bottom: 25px;
}
.step-attention {
    color: #f00;
}
.step-note {
    color: #999;
    font-size: 13px;
    font-size: 1.3rem;
}
.step-img {
    width: 300px;
    float: right;
    /*
        &:after {
            content: '';
            display: block;
            position: absolute;
            background: url(kyuji/icn_step06.png) 0 0/30px no-repeat;
            width: 30px;
            height: 21px;
            bottom: -45px;
            right: 0;
            left: 0;
            margin: auto;;
        }
        */
}
.step-bottomArea {
    width: 100%;
    margin-bottom: 60px;
    line-height: 1.6;
    p + p {
        margin-top: 10px;
    }
}
