#sitemap_detail .sitemap_break {
    margin-bottom: 40px;
    @include clearfix;
    .theme-heading01 {
        margin-bottom: 20px;
    }
    .list {
        float: left;
        width: 370px;
        &:nth-of-type(2n) {
            float: right;
        }
        &:nth-of-type(2n+1) {
            clear: both;
        }
        .sub_title {
            font-size: 16px;
            font-size: 1.6rem;
            line-height: 1;
            padding: 9px 10px;
            background: $themeColor;
        }
        ul {
            display: block;
            vertical-align: top;
            margin-top: 5px;
            li a {
                text-decoration: none;
                font-size: 14px;
                font-size: 1.4rem;
                line-height: 22px;
                background: url(sitemap/icon-list.png) no-repeat left 2px center;
                padding-left: 10px;
            }
        }
    }
    .nav-square {
        width: 100%;
        float: none;
        &-flex {
            display: flex;
        }
        &-list {
            width: calc(50% - 10px);
            &:nth-of-type(2n) {
                padding-left: 20px;
            }
        }
    }
}