.flow {
    &-list {
        width: 734px;
        margin: 0 auto 35px;
    }
    &-item {
        margin-bottom: 30px;
        @include clearfix;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &-tit {
        border: 1px solid $borderColor;
        text-align: center;
        font-size: 19px;
        font-size: 1.9rem;
        box-sizing: border-box;
        width: 106px;
        float: left;
        color: #444;
        padding: 14px 0 16px;
        margin-right: 24px;
    }
    &-num {
        font-size: 31px;
        font-size: 3.1rem;
    }
    &-desc {
        width: 336px;
        float: left;
    }
    &-img {
        width: 250px;
        float: right;
    }
}