@charset "UTF-8";
/* 基本定義 */
body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    font-size: 100%;
    vertical-align: baseline;
}

html {
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 1;
    font-size: 62.5%;
    vertical-align: baseline;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

ol, ul {
    list-style: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

caption, th, td {
    text-align: left;
    font-weight: normal;
    vertical-align: middle;
}

q, blockquote {
    quotes: none;
}

q::before, q::after, blockquote::before, blockquote::after {
    content: "";
    content: none;
}

a img {
    border: none;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}

a {
    color: $shopColor;
    text-decoration: none;
    transition: .3s;
}

@media only screen and (min-width: 1025px) {
    a:hover {
        opacity: .8;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
        text-decoration: underline;
    }
}

img {
    vertical-align: bottom;
}
