@charset "UTF-8";

//Font
$shopColor: #444;

$cartColor: #000;

$themeEng: #94b9b6;

$pinkButton: #fff;

//Link
$anchor : #777777;
$activeColor : #999;

//Border
$borderColor : #fff;
$dottedColor : #ccc;
$borderColorGray : #ccc;
$borderColorPnk : #94b9b6;

// Title Border
$titleBorder: #2b273a;

//Background
$bkColor : #f5f4ef;
$bkColorPink : #94b9b6;

// TEL BK, 10years Notification
$whiteLight: #f6f6f6;

//table
$themeColor: #f6f6f6;

//footer
$themeFooter: #999;

//White Button Gradient
$themeGradientStart: #FFF;
$themeGradientEnd: #f6f6f6;
$themeGradientOrientation: vertical;

// Background Gradient
// $bkGradientStart: #94b9b6;
// $bkGradientEnd: #b9b2cc;
// $bkGradientEndGnavi: #94b9b6;
// $bkGradientOrientation: vertical;

// FAQ, PageUp
$faqAnswer: #94b9b6;


// Font style
$fs: 14px;
$lh : 1.5;

// Contents size
$sp_w: 92%;
$pc_w: 1000px;


//Font-Family
@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 100;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format("opentype");
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 300;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format("opentype");
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format("opentype");
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format("opentype");
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format("opentype");
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 900;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format("opentype");
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 100;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format("opentype");
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 300;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format("opentype");
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 400;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format("opentype");
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 500;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format("opentype");
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 700;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format("opentype");
}

@font-face {
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 900;
    src: local("Noto Sans CJK JP"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format("woff2"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format("woff"), url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format("opentype");
}
$serif: "游明朝","ヒラギノ明朝 Pro W3","Hiragino Mincho Pro","ＭＳ Ｐ明朝","MS PMincho",serif;
$sans_serif: "Noto Sans JP", "游ゴシック", YuGothic, "Yu Gothic","メイリオ", Meiryo,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ＭＳ Ｐゴシック","MS PGothic",Osaka,Helvetica,Arial,sans-serif;
$cart_sans_serif: "游ゴシック", YuGothic, "Yu Gothic","メイリオ", Meiryo,"ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro","ＭＳ Ｐゴシック","MS PGothic",Osaka,Helvetica,Arial,sans-serif;

//Media Query
$sp: "only screen and (max-width: 767px)";
$not_sp: "only screen and (min-width: 768px)";
$tab: "only screen and (min-width: 768px) and (max-width: 1024px)";
$tab_pc: "only screen and (min-width: 768px) and (max-width: 1119px)";
$not_pc: "only screen and (max-width: 1024px)";
$pc: "only screen and (min-width: 1025px)";
$iPhone5: "only screen and (max-width: 374px)";
