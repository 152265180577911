.works {
    &-tit {
        padding-left: 36px;
        margin-bottom: 25px;
    }
    &-box {
        width: 670px;
        background: $themeColor;
        padding: 37px 32px 0;
        margin: -35px auto 40px;
        @include clearfix;
    }
    &-item {
        width: 206px;
        float: left;
        margin: 0 0 15px 26px;
        &:nth-child(3n+1) {
            margin-left: 0;
        }
    }
    &-img {
        margin-bottom: 10px;
    }
}