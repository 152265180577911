#product_detail .item_detail {
    @include clearfix;
    .thumnail {
        width: 300px;
        float: left;
        text-align: center;
        border: 1px solid $borderColor;
    }
}
#product_detail .item_detail .description {
    width: 433px;
    float: right;
    .title {
        font-size: 24px;
        font-size: 2.4rem;
        font-weight: normal;
    }
    .price {
        font-size: 22px;
        font-size: 2.2rem;
        color: #ff0000;
        font-weight: normal;
    }
    .shipping {
        display: block;
        padding: 10px 0 20px;
        line-height: 1;
        li {
            display: inline-block;
            margin-right: 8px;
        }
    }
    .detail-list {
        display: block;
        border-top: 1px dotted #999;
        li {
            margin: 20px 3px 0;
            line-height: 1;
        }
    }
    .box-basket {
        width: 100%;
        display: table;
        background: $themeColor;
        padding: 10px 14px;
        margin-top: 35px;
        text-align: right;
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;
        .side-input {
            display: table-cell;
            vertical-align: middle;
            width: 100%;
            input {
                height: 24px;
                line-height: 24px;
                box-sizing: border-box;
                padding: 4px 10px;
                margin: 0 15px 0 5px;
                width: 90px;
                background: #fff;
                border: 1px solid $borderColor;
                border-radius: 0;
            }
        }
        .side-basket {
            display: table-cell;
            vertical-align: middle;
        }
    }
}
#product_detail .syousai-list {
    padding: 40px 15px;
    @include clearfix;
    .list-break {
        text-align: center;
        display: block;
        overflow: hidden;
        margin-top: 30px;
        .lists {
            width: 300px;
            margin-right: 35px;
            margin-bottom: 18px;
            display: inline-block;
            text-align: center;
            img {
                border: 1px solid $borderColor;
            }
            &:nth-child(even) {
                margin-right: 0;
            }
        }
    }
}