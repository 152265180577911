.footer {
    background: $themeFooter;
    padding: 40px 0 25px;
    position: relative;
    &-copy {
        color: #fff;
        text-align: center;
        font-size: 1.2rem;
        .big {
            font-size: 1.6rem;
            padding: 0 10px;
        }
    }
}
.fnavi {
    margin: 0 auto 40px;
    width: 910px;
    @include clearfix;
    &-left, &-right {
        width: 430px;
        @include clearfix;
    }
    &-left {
        float: left;
    }
    &-right {
        float: right;
    }
    &-tit {
        color: #fff;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        padding: 7px 0;
        margin-bottom: 15px;
        text-align: center;
    }
    &-Bottom {
        margin-bottom: 30px;
    }
    &-box {
        width: 190px;
        float: left;
        margin: 0 50px 30px 0;
        &:nth-of-type(-n+2) {
            margin-top: 0;
        }
        &:nth-of-type(2n) {
            margin-right: 0;
            margin-bottom: 0;
        }
        &:nth-of-type(2n+1) {
            clear: both;
        }
        &.is-right {
            float: right;
            margin: 0;
        }
    }
    &-item {
        font-size: 1.3rem;
        line-height: 2;
        padding-left: 15px;
        width: 100%;
        a {
            position: relative;
            display: block;
            color: #fff;
            padding-left: 1em;
            font-weight: normal;
            &::before {
                content: '';
                border-top: 1px solid #fff;
                border-right: 1px solid #fff;
                width: 3px;
                height: 3px;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%) rotate(45deg);
            }
        }
    }
}