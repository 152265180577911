.nyukou {
  &-intro {
    box-sizing: border-box;
    width: 100%;
    // padding: 5px;
    margin-bottom: 78px;
    background: url(nyukou/nyukou_border.png) repeat top left transparent;
    background-size: 23px auto;
    &-inner {
      box-sizing: border-box;
      width: 100%;
      padding: 36px 40px;
      // background: #fff;
      border: solid 5px $borderColorPnk;
    }
    &-text {
      margin-bottom: 35px;
      font-size: 1.4rem;
      line-height: 1.8;
      letter-spacing: 1px;
    }
    &-title {
      margin-bottom: 28px;
    }
    &-contents {
      display: flex;
      width: 100%;
      &-img {
        display: block;
        width: 63px;
        padding: 0;
        margin: 0;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      &-body {
        width: calc(100% - 63px);
        padding-left: 12px;
        &-text {
          font-size: 1.4rem;
          line-height: 1.8;
          letter-spacing: 1px;
          & + .nyukou-intro-contents-body-text {
            margin-top: 10px;
          }
          a {
            color: $themeEng;
            font-weight: 500;
            letter-spacing: 2.5px;
          }
        }
      }
    }
  }
  .step-item {
    background: none;
    .step-title {
      padding-left: 2.8rem;
      color: $borderColorPnk;
      font-weight: 500;
      font-size: 1.8rem;
      letter-spacing: 1.2px;
      line-height: 1.8;
      text-indent: -2.8rem;
    }
    .step-txt {
      margin-bottom: 10px;
      letter-spacing: 1.2px;
      line-height: 1.8;
      &.is-red {
        color: #ad4242;
      }
    }
    .step-box-ul {
      width: 100%;
      margin-top: 17px;
      li {
        width: 100%;
        padding-left: 2.8rem;
        margin-top: 6px;
        font-weight: 500;
        font-size: 1.6rem;
        letter-spacing: 2px;
        line-height: 1.6;
        text-indent: -2.8rem;
      }
    }
  }
  .step-item:last-child {
    padding-bottom: 10px;
  }
  .step-item01::before {
    background-image: url(nyukou/step1.png);
  }
  .step-item02::before {
    background-image: url(nyukou/step2.png);
  }
  .step-item03::before {
    background-image: url(nyukou/step3.png);
  }
  .step-box {
    width: 550px;
  }
  .step-img {
    width: 48px;
  }
  &-attention {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 74px;
    margin-bottom: 65px;
    background-color: #f5f4ef ;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.6rem;
    letter-spacing: 2px;
    .is-sp {
      display: none;
    }
  }
  .page-intro02 {
    letter-spacing: 2px;
    line-height: 1.8;
  }
  &-checkList {
    width: 100%;
    &-item {
      width: 100%;
      margin-bottom: 30px;
    }
    &-title {
      box-sizing: border-box;
      display: block;
      width: 100%;
      padding: 0 0 0 35px;
      margin-bottom: 16px;
      background: url(nyukou/crayon.png) no-repeat top left transparent;
      background-size: 25px auto;
      letter-spacing: 1.2px;
      line-height: 1.8;
    }
    &-warning {
      box-sizing: border-box;
      display: flex;
      width: 100%;
      .is-pre {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90px;
        height: 30px;
        // border: 1px solid $borderColorPnk;
        border-radius: 30px;
        color: $pinkButton;
        font-size: 1.2rem;
        background-color: $bkColorPink;
      }
      .is-main {
        box-sizing: border-box;
        display: block;
        width: calc(100% - 90px);
        padding-left: 12px;
        font-weight: bold;
        letter-spacing: 1.2px;
        line-height: 1.8;
        &.is-1row {
          padding-top: 5px;
        }
      }
    }
  }
}
/* デザインに合わせて調整 */
.theme-nyukou {
  margin-bottom: 47px;
}