.notification {
    &-box {
        background: $whiteLight;
        padding: 22px 26px 22px 46px;
        margin-bottom: 50px;
    }
    &-tit {
        color: #F00;
        font-size: 15px;
        font-size: 1.5rem;
        position: relative;
        margin-bottom: 10px;
        &::before {
            content: '!';
            font-size: 26px;
            font-size: 2.6rem;
            line-height: 1;
            margin: 0 12px 0 2px;
            display: inline-block;
            font-weight: bold;
        }
    }
    &-attention {
        color: #F00;
    }
    &-list {
        line-height: 1.7;
    }
    &-item {
        padding-left: 1em;
        text-indent: -1em;
    }
}