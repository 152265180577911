.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    @include clearfix;
}
.slick-track::before {
    content: "";
    display: table;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
    a {
        display: block;
    }
}
[dir="rtl"] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
    &.slick-loading img {
        display: none;
    }
    &.dragging img {
        pointer-events: none;
    }
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
/* Slider */
.slick-loading .slick-list {
    background: #fff url("ajax-loader.gif") center center no-repeat;
}
/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 25px;
    width: 25px;
    background: transparent;
    color: transparent;
    top: 50%;
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    &:focus {
        outline: none;
        background: transparent;
        color: transparent;
    }
    &:focus::before {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
    &.slick-disabled:before {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    }
}
.slick {
    &-prev {
        left: -13%;
        background: url(../img/button/btn_prev.png) 0 0/25px no-repeat;
    }
    &-next {
        right: -13%;
        background: url(../img/button/btn_next.png) 0 0/25px no-repeat;
    }
}
/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -20px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
}

.slick-dots li {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    padding: 0;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 50%;
    background: #ddd;
}

.slick-dots li button {
    font-size: 0;
    appearance: none;
    border: none;
    background: transparent;
}

.slick-dots li button:focus {
    outline: none;
}

.slick-dots li button:focus:before {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.slick-dots li.slick-active {
    background: $activeColor;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
