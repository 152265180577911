.top-box {
    margin-bottom: 35px;
}
.top-scene {
    &-list {
        margin-left: -26px;
        @include clearfix;
    }
    &-item {
        float: left;
        width: 365px;
        margin: 0 0 22px 26px;
        border: 1px solid $borderColor;
        &.is-small {
            width: 234px;
        }
    }
}
.top-category {
    &-tit::after {
        display: none;
    }
    &-box {
        background-color: $bkColor;
        padding: 32px 32px 12px;
        margin: -25px 0 20px;
    }
    &-list {
        @include clearfix;
    }
    &-item {
        width: 214px;
        float: left;
        margin: 0 0 20px 26px;
        &:nth-child(3n+1) {
            margin-left: 0;
        }
    }
    &-other {
        width: 334px;
        float: left;
        margin-bottom: 20px;
        &:nth-child(odd) {
            margin-right: 26px;
        }
    }
}
.top-shopping {
    &-list {
        background-color: $bkColor;
        padding: 32px 32px 12px;
        @include clearfix;
    }
    &-item {
        width: 334px;
        float: left;
        margin-bottom: 20px;
        &:nth-child(odd) {
            margin-right: 26px;
        }
    }
}
.top-aboutUs {
    margin-bottom: 45px;
    &-wrap {
        display: flex;
        justify-content: space-between;
    }
    &-img {
        display: block;
        width: 380px;
        order: 1;
        margin-right: 14px;
        img {
            width: 100%;
            height: auto;
        }
    }
    &-text {
        width: 366px;
        order: 2;
        &-item {
            font-size: 1.4rem;
            margin-bottom: 18px;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}