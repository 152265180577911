div.box {
    // @include gradient($bkGradientStart, $bkGradientEnd, 'diagonal');
    background-color: $bkColor;
    color: #444;
    padding: 10px 14px;
    margin-bottom: 20px;
    text-align: center;
    &-cat {
        border: 1px solid $borderColorGray;
        padding: 20px 20px 0;
        margin-bottom: 25px;
    }
}
div.navi-ttl {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}
/* カテゴリリンク */
div.cate-in {
    padding: 8px 4px;
    font-size: 90%;
}
div.note-link {
    background: #fff;
    padding: 5px 0;
}
.side-entry {
    margin-bottom: 7px;
}
.side-search {
    border: 1px solid $borderColorGray;
    width: 167px;
    margin: 0 auto;
    @include clearfix;
    input {
        float: left;
        appearance: none;
        height: 24px;
        line-height: 24px;
        border: none;
        box-sizing: border-box;
        &#find {
            width: 135px;
            background: #fff;
            border-right: 1px solid $borderColorGray;
        }
        &[type='submit'] {
            width: 32px;
            background: url(btn_search.png) 0 0 no-repeat;
            font-size: 0;
            cursor: pointer;
        }
    }
}
.side-bnr {
    margin-bottom: 25px;
}
.side-list {
    text-align: left;
    margin-bottom: 10px;
}
.side-cat {
    &-tit {
        color: #444;
        margin-bottom: 10px;
        padding-left: 30px;
    }
    &-subtit {
        font-size: 1.3rem;
        // @include gradient($bkGradientStart, $bkGradientEnd, 'diagonal');
        background-color: $bkColorPink;
        color: #fff;
        padding: 1px 0 1px 7px;
    }
    $sideIcons: stamp, corporative, other;
    @each $sideIcon in $sideIcons {
        &-#{$sideIcon} {
            background: url(icn_#{$sideIcon}.png) 4px center no-repeat;
        }
    }
}
.side-item {
    font-size: 1.3rem;
    border-bottom: 1px dotted $dottedColor;
    &:last-child {
        border-bottom: none;
    }
    a {
        display: block;
        position: relative;
        padding: 6px 0 6px 20px;
        color: $anchor;
        &::before {
            content: '';
            border-top: 1px solid $borderColorGray;
            border-right: 1px solid $borderColorGray;
            width: 3px;
            height: 3px;
            position: absolute;
            top: 50%;
            left: 8px;
            transform: translateY(-50%) rotate(45deg);
        }
    }
}
.box a {
    color: #444;
}
.side-hover > a {
    &::before {
        transform: translateY(-50%) rotate(135deg);
    }
    &.on::before {
        transform: translateY(-50%) rotate(-45deg);
    }
}
.side-hover > ul {
    display: none;
    li a::before {
        display: none;
    }
}
.ttl-in {
    &-about {
        background: url(icn_about.png) 4px center no-repeat;
        padding-left: 15px;
    }
    &-shopping {
        background: url(icn_shopping.png) 4px center no-repeat;
    }
}