.aboutPage {
  margin-bottom: 70px;
  .is-pc {
    display: block;
  }
  .is-sp {
    display: none;
  }
  .is-bold {
    font-weight: 500;
  }
  .is-big {
    color: $themeEng;
    font-weight: 500;
    font-size: 1.8rem;
  }
  .theme-heading01 {
    margin-bottom: 30px!important;
  }
  &-intro {
    margin-bottom: 70px;
    .page-txt {
      line-height: 1.8;
    }
  }
  &-text {
    padding: 10px 0;
    margin: 0 0 50px;
    font-size: 1.4rem;
    letter-spacing: 1px;
    line-height: 1.8;
  }
  &-merit {
    display: flex;
    width: 100%;
    margin-bottom: 45px;
    &-term {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 105px;
      height: 105px;
      border: 1px solid $borderColorGray;
      .is-num {
        margin-bottom: 10px;
        font-weight: lighter;
        font-size: 3.6rem;
        line-height: 1;
      }
      .is-text {
        font-size: 1.6rem;
        line-height: 1;
      }
    }
    &-description {
      box-sizing: border-box;
      display: flex;
      width: calc(100% - 105px);
      padding: 0 0 0 20px;
      font-size: 1.4rem;
      letter-spacing: 1px;
      line-height: 1.8;
      &-body {
        box-sizing: border-box;
        width: 59.4%;
        padding-right: 10px;
        line-height: normal;
      }
      &-img {
        box-sizing: border-box;
        display: block;
        width: 40.6%;
        padding: 3px;
        margin: 0;
        border: 1px solid $borderColorGray;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
  &-idea {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    & + .aboutPage-text {
      margin-top: 40px;
    }
    &-term {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 30px;
      border: 1px solid $themeEng;
      border-radius: 30px;
      color: $themeEng;
      font-size: 1.2rem;
      line-height: normal;
    }
    &-description {
      box-sizing: border-box;
      width: calc(100% - 90px);
      min-height: 27px;
      padding: 5px 0 0 45px;
      background: url(about/icon_idea.png) no-repeat top left 14px transparent;
      background-size: 25px auto;
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }
  &-approach {
    box-sizing: border-box;
    width: 100%;
    padding: 30px 10px 40px;
    margin-bottom: 25px;
    background-color: $bkColor;
    &-term {
      width: 100%;
      margin-bottom: 10px;
      .is-icon {
        display: block;
        width: 38px;
        margin: 0 auto 14px;
        &.approach02 {
          width: 21px;
        }
        &.approach03 {
          width: 41px;
        }
        &.approach04 {
          width: 37px;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .is-title {
        display: block;
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 1.5;
      }
    }
    &-description {
      width: 100%;
      text-align: center;
      line-height: 1.6;
    }
  }
}
