.contact-table {
    width: 734px;
    margin: 0 13px 14px;
    th {
        vertical-align: top;
        box-sizing: border-box;
        padding: 28px 0 0 22px;
        width: 190px;
    }
    td {
        padding: 14px 0;
    }
}
.contact-input[type=text], .contact-textarea {
    background: $themeColor;
    border: none;
    &:focus {
        outline: none;
    }
    &.contact-error {
        color: #444;
    }
}
.contact-input[type=text] {
    width: 360px;
}
.contact-textarea {
    width: 545px;
    height: 140px;
}
.contact-btn {
    width: 170px;
    margin-left: 203px;
    input {
        width: 100%;
    }
}
.contact-error {
    display: block;
    color: #f00;
}