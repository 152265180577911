.career {
    &-box {
        background: $themeColor;
        margin: 0 13px 68px;
        padding: 36px 46px;
    }
    &-tit {
        font-weight: bold;
        margin-bottom: 10px;
    }
    &-list {
        line-height: 2.5;
        @include clearfix;
    }
    &-date {
        font-weight: bold;
        clear: both;
        width: 95px;
        float: left;
        margin-right: 20px;
    }
    &-desc {
        width: 527px;
        float: left;
    }
}