.l {
    &-inner {
        width: $pc_w;
        margin: 0 auto;
    }
    &-header-left {
        width: auto;
        float: left;
    }
    &-header-right {
        width: 503px;
        float: right;
        @include clearfix;
    }
    &-contents {
        width: $pc_w;
        margin: 0 auto 75px;
        @include clearfix;
    }
}
#main {
    float: right;
    width: 760px;
}
#navi {
    float: left;
    width: 210px;
}