.calendar .ttl-in {
    color: $shopColor;
}
table.calen {
    border-collapse: collapse;
    font-size: 1.3rem;
    color: $shopColor;
    width: 170px;
    margin: 0 auto;
    th, td {
        border-bottom: 1px solid #c0c0c0;
        padding: 4px;
        background: #fff;
        text-align: center;
    }
}
tr.day td {
    color: #616161;
}
td.sun {
    color: #ff6262;
}
td.sat {
    color: #4d4dff;
}
div.calen-memo {
    color: #666;
    margin: 4px 0;
    span.hol {
        color: #ffa6b6;
    }
    span.tod {
        color: #ff2d2d;
    }
}
div.hol {
    background: #ffa6b6;
}
div.tod {
    border: 1px solid #ff2d2d;
}
div.calendar {
    background: #fff;
    border: 1px solid $borderColorGray;
}
.calendar-month {
    text-align: center;
    font-weight: bold;
}