.privacy {
    &-box {
        margin: 0 13px 30px;
    }
    &-link {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}