.theme {
    &-heading01 {
        font-size: 2.1rem;
        color: $shopColor;
        border-left: 4px solid $titleBorder;
        padding-left: 17px;
        line-height: 1.3;
        margin-bottom: 15px;
    }
    &-eng {
        font-size: 1.3rem;
        line-height: 28px;
        color: $themeEng;
        padding-left: 1em;
    }
    &-pagetit {
        margin-bottom: 25px;
    }
}
.theme-tit {
    padding-left: 40px;
    position: relative;
    margin-bottom: 15px;
    &::after {
        content: '';
        display: block;
        width: 654px;
        height: 1px;
        background: $borderColor;
        position: absolute;
        top: 50%;
        right: 0;
    }
    $sideIcons: square, rectangle, other;
    @each $sideIcon in $sideIcons {
        &-#{$sideIcon} {
            background: url(icn_#{$sideIcon}.png) 15px center no-repeat;
        }
    }
}
.theme-table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    thead th {
        padding: 18px 0;
    }
    .bd-none {
        td {
            border-bottom: none;
        }
        & + tr td {
            border-top: none;
        }
    }
    th, td {
        border: 1px solid $borderColorGray;
        color: $shopColor;
    }
    th {
        background: $themeColor;
        text-align: center;
        font-size: 15px;
        font-size: 1.5rem;
    }
    td {
        text-align: center;
        background: #fff;
    }
    &-note {
        font-size: 12px;
        font-size: 1.2rem;
        font-weight: normal;
    }
    &.is-short {
        width: 542px;
    }
    &-size td {
        font-size: 16px;
        font-size: 1.6rem;
        font-weight: bold;
        padding: 19px 0 18px;
    }
}
.theme-txtTable {
    margin: 0 0 35px 0;
    th, td {
        text-align: left;
        box-sizing: border-box;
        padding: 23px 20px;
    }
    th {
        vertical-align: top;
        width: 190px;
    }
    td {
        line-height: 1.6;
    }
    &-emph {
        font-weight: bold;
    }
    &-attention {
        color: #f00;
    }
    &-link {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    &-list {
        margin-bottom: 1.6em;
        &:last-child {
            margin-bottom: 0;
        }
        dt {
            font-weight: bold;
        }
    }
    th.table-title {
        text-align: center;
        font-weight: bold;
    }
}
input[type=text], input[type=password], input[type=email], input[type=tel], input[type=search], input[type=zip] {
    appearance: none;
    background: #fff;
    border-radius: 2px;
    border: 1px solid #b9c0bc;
    padding: 15px 10px;
    max-width: 100%;
    box-sizing: border-box;
    font-family: $sans_serif;
}
input[type=checkbox] {
    appearance: none;
    border: none;
    vertical-align: middle;
    margin: 0;
}
input[type=checkbox] + label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    &::before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border: 1px solid #b9c0bc;
        background: #fff;
        position: absolute;
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
    }
    &::after {
        content: "";
        display: block;
        width: 6px;
        height: 12px;
        position: absolute;
        top: 2px;
        left: -16px;
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        transition: .2s;
        transform: rotate(45deg);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}
input[type=checkbox]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
input[type=radio] {
    appearance: none;
    display: none;
    border: none;
    vertical-align: middle;
    margin: 0;
}
input[type=radio] + label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    &::before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border: 1px solid #b9c0bc;
        background: #fff;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: -20px;
        transform: translateY(-50%);
    }
    &::after {
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        background: #000;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: -16px;
        transition: .2s;
        transform: translateY(-50%);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}
input[type=radio]:checked + label:after {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
textarea {
    padding: 15px 10px;
    border: 1px solid #b9c0bc;
    background: #fff;
    border-radius: 2px;
    box-sizing: border-box;
    font-family: "Noto Sans JP", "游ゴシック", YuGothic, "Yu Gothic", "メイリオ", Meiryo, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "ＭＳ Ｐゴシック", "MS PGothic", Osaka, Helvetica, Arial, sans-serif;
}
.sp {
    display: none !important;
}
.image-title {
    margin-bottom: 25px;
}
.item-title {
    background-color: $bkColor;
    border: 1px solid #e9e9e9;
    margin-bottom: 25px;
    padding: 9px 15px 11px;
    font-size: 2.4rem;
    line-height: 1.2;
    letter-spacing: .1em;
}
.sub-title {
    margin-bottom: 25px;
    padding: 2px 0 3px 15px;
    border-left: 4px solid $titleBorder;
    font-size: 21px;
    font-size: 2.1rem;
    line-height: 1.2;
    letter-spacing: .1em;
    span, strong {
        display: inline-block;
        vertical-align: middle;
        line-height: 1.2;
    }
    strong {
        margin-left: .4em;
        font-size: 30px;
        font-size: 3rem;
    }
}