.list-col1 {
    margin-top: 15px;
    li a {
        display: block;
    }
    &-center {
        margin-bottom: 20px;
        li a {
            display: block;
            width: 60%;
            margin: 0 auto;
        }
    }
}
.list-col3 {
    margin-bottom: 20px;
    @include clearfix;
    &-item {
        width: 236px;
        float: left;
        margin-left: 26px;
        &:first-child {
            margin-left: 0;
        }
    }
}
.list-col2 {
    margin-bottom: 40px;
    @include clearfix;
    &-item {
        width: 367px;
        float: left;
        &:first-child {
            margin-right: 26px;
        }
    }
    & + .list-col2 {
        margin-top: -20px;
    }
}
.list-zaishitsu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;
    &-item {
        opacity: 0;
        transition: opacity .3s;
        width: 230px;
        float: left;
        color: $shopColor;
        margin: 0 0 30px 22px;
        &:nth-child(4n+1) {
            margin-left: 0;
        }
        &.is-loaded {
            opacity: 1;
        }
        img {
            margin-bottom: 13px;
        }
    }
    &-title {
        width: 103px;
        height: 103px;
        border: 1px solid $borderColor;
        text-align: center;
        font-size: 18px;
        line-height: 18px;
    }
    &-num {
        display: block;
        font-size: 31px;
        line-height: 31px;
        padding: 22px 0 16px 0;
    }
    &-wrap {
        display: flex;
        flex-basis: 630px;
        margin-bottom: 33px;
    }
    &-txt {
        padding-right: 27px;
        line-height: 1.6;
    }
    &-name {
        font-weight: bold;
        margin-bottom: 5px;
    }
    &-note {
        font-size: 1.2rem;
        color: #999;
        display: block;
        padding-top: 1.5em;
    }
}
// .zaishitsu {
//     &-intro {
//         display: flex;
//         justify-content: space-between;
//         margin-bottom: 72px;
//         &-text {
//             width: 420px;
//         }
//         &-img, &-img img {
//             width: 300px;
//         }
//     }
//     &-text {
//         margin-bottom: 30px;
//         &-last {
//             margin-bottom: 0;
//         }
//     }
// }
// .bnr-premium {
//     text-align: center;
//     padding-top: 26px;
//     margin-bottom: 47px;
// }
// #zaishitsu_detail .theme-heading01 {
//     margin-bottom: 24px;
// }