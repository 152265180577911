.card {
    &-list {
        margin: 0 13px;
        margin-bottom: 40px;
        @include clearfix;
    }
    &-item {
        width: 354px;
        float: left;
        box-sizing: border-box;
        padding: 14px 17px;
        border: 1px solid $borderColor;
        margin: 0 0 22px 26px;
        @include clearfix;
        &:nth-child(odd) {
            margin-left: 0;
        }
        &:nth-child(5), &:nth-child(6) {
            margin-bottom: 0;
        }
    }
    &-box {
        width: 173px;
        float: right;
        padding-top: 10px;
    }
    &-tit {
        font-size: 15px;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 5px;
    }
    &-desc {
        font-size: 13px;
        font-size: 1.3rem;
    }
    &-img {
        width: 110px;
        float: left;
    }
}