@charset "UTF-8";

@import "../components/variables";
@import "../components/mixin";
@import "../components/exvalidation";
@import "../components/reset";
@include shopBody;
@import "../components/theme";

/* basic setting
======================================================*/
/* clearfix */
.clearfix {
    clear: both;
}

.clearfix:before, .clearfix:after {
    content: ' ';
    display: table;
}

.clearfix:after {
    clear: both;
}

/* Layout
======================================================*/
@import "../components/layout";

/* Header
======================================================*/
@import "../components/header";

/* Gnavi
======================================================*/
@import "../components/gnavi";


/* Footer
======================================================*/
@import "../components/footer";
@import "../components/pagetop";

a:hover#mttl {
    text-decoration: none;
}

/* TOPボックス */
/* Slider */
@import "../components/slick";

@import "../components/visual";

@import "../components/top";
@import "../components/contactBnr";


/* Components
======================================================*/
@import "../components/page";

/* TOPナビ */
@import "../components/breadcrumb";

/* List */
@import "../components/list";

/* Product List */
/* Product Detail */
@import "../components/productDetail";

/* Tab */
@import "../components/tab";

/* Step */
@import "../components/step";

/* Works */
@import "../components/works";

/* Flow */
@import "../components/flow";


/* Sample */
@import "../components/sample";

/* Card */
@import "../components/card";

/* Youtube */
@import "../components/youtube";

/* Career */
@import "../components/career";

/* Modal */
@import "../components/modal";

/* Notification */
@import "../components/notification";

/* Faq */
@import "../components/faq";

/* Payment */
@import "../components/payment";

/* Privacy */
@import "../components/privacy";

/* Contact */
@import "../components/contact";

/* Xstamper */
/* Tebori */
/* Tegaki */
@import "../components/tegaki";

/* Kaisyagaiyou */
@import "../components/companyProfile";

/* Category List */
@import "../components/categoryList";

/* Category Detail */
@import "../components/categoryDetail";

/* Sitemap */
@import "../components/sitemap";

table.top-tbl {
    width: auto;
    margin: 0 auto;
}

table.top-tbl td {
    text-align: center;
    padding: 8px;
}

div.subttl-box {
    width: auto;
    margin: 1.2em auto;
    font-weight: bold;
    font-size: 115%;
}

/* TOPナビ */
/* 情報ボックス */
div.news-box {
    width: 640px;
    margin: 1.2em auto;
    text-align: left;
    border-top: 1px solid #a99770;
    /* letter-spacing: 1px; */
    line-height: 160%;
    background: #fff;
}

div.news-ttl {
    background: #a99770;
    color: #fff;
    letter-spacing: 1px;
    font-weight: bold;
}

/* 商品購入カゴ */
table.item-tbl {
    margin: 0 auto;
    width: auto;
}

table.item-tbl td {
    padding: 3px;
}

table.item-tbl strong, table.detail-tbl strong {
    color: #ce6700;
    font-size: 115%;
}

table.item-tbl span, table.detail-tbl span {
    color: #808000;
}

.item-com {
    width: 330px;
    text-align: left;
}

.item-cart {
    width: 150px;
    text-align: center;
}

.item-tbl p {
    margin-top: 5px;
    font-size: 90%;
    color: #444;
}

table.item-tbl fieldset, table.detail-tbl fieldset {
    padding: 8px 3px;
    font-size: 90%;
    border: 1px solid #aaa;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
}

table.item-tbl legend, table.detail-tbl legend {
    color: #808000;
}

input.form-num {
    width: 60px;
    height: 20px;
    ime-mode: inactive;
    border: 1px solid #6f6042;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}

td.pt-memo {
    color: #6c563c;
    font-size: 90%;
}

td.pt-memo b {
    color: #dd0000;
}

/* 詳細ページ */
table.detail-tbl {
    width: 640px;
}

table.detail-tbl td {
    width: 320px;
}

table.detail-tbl td.image {
    text-align: center;
    vertical-align: top;
    padding: 1.2em 0;
}

hr.dot-line {
    height: 2px;
    background-color: #f5b48f;
    border: none;
    color: #f5b48f;
    width: 95%;
    margin: 1.5em 0;
}

.item-comment {
    border-top: 2px solid #f5b48f;
    border-bottom: 2px solid #f5b48f;
    margin: 2em auto;
    padding: 1.2em 1em;
    width: 90%;
    text-align: left;
}

table.detail-tbl fieldset {
    width: 90%;
}

/* 会員フォーム */
/* 情報案内ページ */
table#note-tbl {
    border-collapse: collapse;
    margin: 1em auto;
}

table#note-tbl th, table#note-tbl td {
    padding: 6px;
    border: 1px solid #444;
    font-size: 90%;
}

table#note-tbl th {
    background: #ddd;
    white-space: nowrap;
}

table#note-tbl td {
    text-align: left;
    background: #fff;
}

/* ナビボックス */
@import "../components/sidebar";

div.remind {
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
}

/* ログイン */
div.login {
    margin: 8px 0 8px 12px;
}

div.login input[type="text"], div.login input[type="password"] {
    width: 165px;
    height: 20px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border: 1px solid #ffa66a;
}

/* カレンダ */
@import "../components/calendar";

/* 正常・エラー画面 */
div#msg-box {
    margin: 2em 0 15em 2em;
    text-align: center;
    width: 480px;
    background: #fff;
    border: 1px solid #555;
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    box-shadow: 0px 0px 4px 2px #cac4ac;
    -webkit-box-shadow: 0px 0px 4px 2px #cac4ac;
    -moz-box-shadow: 0px 0px 4px 2px #cac4ac;
}

div#msg-box p {
    margin: 1.2em;
}

div#err-box {
    margin: 1em auto;
    width: 400px;
    text-align: left;
    color: #ff3c3c;
}

div#com-box {
    margin: 1em auto;
    width: 400px;
    text-align: center;
    color: #006a00;
}

div.code {
    color: #ff6317;
    font-size: 90%;
    margin: 0 0 2px 0;
}

div.cart-btn img {
    margin: 1.2em 2em;
}

input.ct-num {
    width: 25px;
    ime-mode: inactive;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border: 1px solid #555;
}

input.chg-btn {
    padding: 4px 2px;
    font-size: 85%;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border: 1px solid #555;
}

/* リサイズ画像 */
img.resize {
    display: none;
    border: none;
}

/* ボタン */
img.btn {
    border: none;
}

/* アイコン */
.icon {
    vertical-align: middle;
    border: none;
}

/* 画像マウスオーバエフェクト */
a:hover img, input[type="image"]:hover {
    opacity: 0.8;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
}

/* 赤文字 */
.red {
    color: #dd0000;
}

/* 寄せ */
.ta-c {
    text-align: center !important;
}

.ta-l {
    text-align: left !important;
}

.ta-r {
    text-align: right !important;
}

#main > .ta-c {
    text-align: left !important;
    font-size: 14px;
}

#main > .ta-c img {
    margin-top: 20px;
}

/*
	参考 http://weboook.blog22.fc2.com/blog-entry-329.html
*/
table.cart-tbl {
    margin: 1em auto;
    width: auto;
    border-spacing: 0;
}

table.cart-tbl th {
    color: #000;
    padding: 8px 15px;
    background: #eee;
    background: -moz-linear-gradient(#eee, #ddd 50%);
    background: -webkit-gradient(linear, 100% 0%, 100% 50%, from(#eee), to(#ddd));
    font-weight: bold;
    border-top: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    line-height: 120%;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.9);
    box-shadow: 0px 1px 1px rgba(255, 255, 255, 0.3) inset;
}

table.cart-tbl th:first-child {
    border-left: 1px solid #aaa;
    border-radius: 5px 0 0 0;
}

table.cart-tbl th:last-child {
    border-radius: 0 5px 0 0;
    border-right: 1px solid #aaa;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
}

table.cart-tbl tr td {
    padding: 8px 15px;
    text-align: center;
}

table.cart-tbl tr td:first-child {
    border-left: 1px solid #aaa;
}

table.cart-tbl tr td:last-child {
    border-right: 1px solid #aaa;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
}

table.cart-tbl tr {
    background: #fff;
}

table.cart-tbl tr:nth-child(2n+1) {
    background: #f5f5f5;
}

table.cart-tbl tr:last-child td {
    border-bottom: 1px solid #aaa;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.1);
}

table.cart-tbl tr:last-child td:first-child {
    border-radius: 0 0 0 5px;
}

table.cart-tbl tr:last-child td:last-child {
    border-radius: 0 0 5px 0;
}

table.cart-tbl tr:hover {
    background: #eee;
    cursor: pointer;
}

/* 確認画面 */
.w-item {
    width: 320px !important;
}

/* 購入履歴・問い合わせフォーム */
p.ttl-history {
    width: 590px !important;
    margin: 18px auto 8px auto;
    text-align: left;
    font-weight: bold;
}

.f-12 {
    font-size: 12px;
}

.col-brown {
    color: #80410d;
}

/* 注文者情報 */
table.prog-tbl td span {
    font-size: 12px;
    color: #006a00;
}

td.login-form {
    width: 280px !important;
}

fieldset.user-meth {
    padding: 8px 12px;
    border: 1px solid #aaa;
    line-height: 150%;
}

fieldset.user-meth legend {
    color: #006a00;
    font-size: 12px;
}

/* フォーム長 */
input.w-240 {
    width: 240px;
    border: 1px solid #666;
    padding: 4px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}

input.w-120 {
    width: 120px;
    border: 1px solid #666;
    padding: 4px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}

input.w-40 {
    width: 40px;
    border: 1px solid #666;
    padding: 4px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}

textarea.w-ta {
    width: 320px;
    height: 3em;
    border: 1px solid #666;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
}

/* 注文方法選択 */
table.prog-tbl div.ord-sel {
    margin: 4px 0 4px 15px;
}

/* 現在のポイント */
div.pt-now {
    margin-bottom: 4px;
    color: #006f00;
}

/* メルマガ・検索窓 */
#mailmag, #find {
    width: 110px;
    padding: 4px 2px;
}

/*  ページャー*/
ul.pager {
    clear: both;
    margin: 0 0 10px;
    padding: 10px 10px 5px;
}

ul.pager li {
    display: inline;
    margin: 0 2px;
    padding: 0;
}

ul.pager li span {
    display: inline-block;
    margin-bottom: 5px;
    padding: 3px 8px;
    background: $faqAnswer;
    color: #fff;
    border: 1px solid $faqAnswer;
    text-decoration: none;
    vertical-align: middle;
}

ul.pager li a {
    display: inline-block;
    margin-bottom: 5px;
    padding: 3px 8px;
    background: #fff;
    color: $shopColor;
    border: 1px solid $faqAnswer;
    text-decoration: none;
    vertical-align: middle;
}

ul.pager li a:hover {
    background: #ffc488;
}

//inei
.inei-empty {
    display: block;
    margin: 5px auto;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: $themeColor;
}

/* nyukou */
@import "../components/nyukou";
/* aboutPage */
@import "../components/aboutPage";
/* xstamperPage */
@import "../components/xstamperPage";
/* inkChangePage */
@import "../components/inkChange";


